import axios from '../../client/axios'
import { BASE_URL_API_LOYALTY } from '../../settings'

export const LoyaltyRequestBodyDTO = ({ trip, passenger, cardNumber }) => ({
  // "email": "string",
  // "mobilePhone": "string",
  cardNumber,
  fetchDiscounts: true,
  tripInfo: {
    originCode: trip?.origin?.idOrCode,
    destinationCode: trip?.destination.idOrCode,
    departureDateTime: trip?.departureDateTime,
    vesselId: trip?.vessel?.idOrCode
  }
})

/**
 * This method get passenger loyalty card info
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - The request body params {
    "email": "string",
    "mobilePhone": "string",
    "cardNumber": "string",
    "fetchDiscounts": true,
    "tripInfo": {
        "originCode": "string",
        "destinationCode": "string",
        "departureDateTime": "string",
        "vesselId": "string"
    }
}
 * @property { String } params.token - The access token for authorization
 */
export const apiLoyaltyInfo = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_LOYALTY,
    url: process.env.API_BASE_PATH_GUI + '/info',
    headers: { Authorization: `Bearer ${token}` },
    data
  }).then(res => res.data)
