import axios from '../../client/axios'
import { BASE_URL_API_GUI } from '../../settings'

/**
 * The response of the method, contains two lists of “Area” entities, in JSON format. The structure of the entity can be found on the table, below.
 * @param { Object } params - The params object
 * @param { String } params.token - Access token string
 * @param { String } params.locationType Possible values are HARBOUR, GENERIC_LOCATION, BUS_STOP etc.
 * Example response:
 *
 * @returns { Promise }
 *
 * Example response:
 * [
    {
      "locationType": "string",
      "idOrCode": "string",
      "name": "string",
      "country": {
        "idOrCode": "string",
        "name": "string",
        "telephonePrefix": [
          "string"
        ]
      },
      "latitude": 0,
      "longitude": 0,
      "locationLanguages": [
        {
          "id": 0,
          "location": {},
          "language": {
            "id": 0,
            "code": "string",
            "name": "string"
          },
          "name": "string",
          "channel": "string",
          "displayOrder": 0
        }
      ],
      "areas": [
        {
          "idOrCode": "string",
          "name": "string"
        }
      ]
    }
  ]
 */
export const apiLocations = ({ locationType, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/locations',
    headers: token,
    params: {
      locationType
    }
  }).then(res => res.data)

/**
 * Get all locations of type harbour
 * @param { Object } params - Object params
 * @param { String } params.token - Access token string
 * @returns { Promise }
 */
export const apiHarbourLocations = params => apiLocations({ ...params, locationType: 'HARBOUR' })

/**
 * The method is used to search within the locations. Search can be performed on the Location Code,
 * the Location Description and Country Code. A full match is required for any of these fields.
 *
 * @param { Object } params - Params
 * @param { String } params.locationType - Possible values are HARBOUR, GENERIC_LOCATION, BUS_STOP etc.
 * @param { String } params.searchText - A String to search for within Location Code, Location Description or Country Code
 * @param { String } params.token - Access token string
 * Example response: [
    {
      "locationType": "string",
      "idOrCode": "string",
      "name": "string",
      "country": {
        "idOrCode": "string",
        "name": "string",
        "telephonePrefix": [
          "string"
        ]
      },
      "latitude": 0,
      "longitude": 0,
      "locationLanguages": [
        {
          "id": 0,
          "location": {},
          "language": {
            "id": 0,
            "code": "string",
            "name": "string"
          },
          "name": "string",
          "channel": "string",
          "displayOrder": 0
        }
      ],
      "areas": [
        {
          "idOrCode": "string",
          "name": "string"
        }
      ]
    }
  ]
 */
export const apiLocationsWithFilter = ({ locationType, searchText, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/locations-with-filter',
    headers: token,
    params: {
      locationType,
      searchText
    }
  }).then(res => res.data)

/**
 * Get locations with filter
 *
 * @param { Object } params - Params
 * @param { String } params.searchText - A String to search for within Location Code, Location Description or Country Code
 * @param { String } params.token - Access token string
 * @returns { Promise }
 */
export const apiHarbourLocationsWithFilter = params => apiLocationsWithFilter({ ...params, locationType: 'HARBOUR' })
