export const RELEASE_NOTES =  process.env.RELEASE_NOTES 

export const BASE_URL_API_B2B = process.browser ? process.env.API_B2B_BASE_URL_ON_BROWSER : process.env.API_B2B_BASE_URL

export const BASE_URL_API_CHECKIN = process.env.ENVIRONMENT === "web" ? "/" : process.browser
  ? process.env.API_CHECKIN_BASE_URL_ON_BROWSER
  : process.env.API_CHECKIN_BASE_URL

export const BASE_URL_API_TRUCKS = process.env.ENVIRONMENT === "web" ? "/" : process.browser
  ? process.env.API_TRUCKS_BASE_URL_ON_BROWSER
  : process.env.API_TRUCKS_BASE_URL

export const BASE_URL_API_LOYALTY = process.env.ENVIRONMENT === "web" ? "/" : '/api/LoyaltyService'
export const BASE_URL_API_GUI = process.env.ENVIRONMENT === "web" ? "/" : process.env.API_BASE_URL
export const API_PRINTER = process.browser ? process.env.API_PRINTER : process.env.API_PRINTER