import axios from '../../client/axios'
import { BASE_URL_API_CHECKIN } from '../../settings'

/**
 * This method brings all the available ports for checkin
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.params - The request query params
 * @property { String } params.token - The access token for authorization
 */
export const apiCheckinAvailablePorts = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_CHECKIN,
    url: process.env.API_BASE_PATH_GUI + '/AvaliliablePorts',
    headers: { Authorization: `Bearer ${token}` },
    params
  }).then(res => res.data)
