import axios from '../../client/axios'
import { BASE_URL_API_CHECKIN } from '../../settings'

/**
 * This method changes the user status to checked out
 * documentation http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - The request body params {
 *  StopCode: string
 *  PlateNumber: string
 *  ItineraryId: integer
 *  TicketNumber: string
 *  Comments: string
 * }
 * @property { String } params.token - The access token for authorization
 */
export const apiCheckout = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_CHECKIN,
    url: process.env.API_BASE_PATH_GUI + '/CheckOut',
    headers: { Authorization: `Bearer ${token}` },
    // params,
    data
  }).then(res => res.data)
