import moment from 'moment'
import axios from '../../client/axios'
import { BASE_URL_API_GUI } from '../../settings'

/**
 * This method fetches all the possible routes for each transportation type.
 * Specifically for the bus transportation part, data fetched include only those bus operators, who support this functionality.
 * The integrators should contact FORTHcrs about the companies that support the functionality.
 * The response of the method, is a list of “Transportation” entities, in JSON format.
 *
 * @param { Object } params - Params
 * @param { String } params.token - Access token string
 * @param { String } params.transportationType Possible values are BT (data for bus transportation), ST (data for sea transportation) and AT (data for both sea and bus transportation). Default value, is AT
 *
 * @returns { Promise }
 *
 * Example response:
 * [
      {
        "type": "string",
        "routes": [
          {
            "origin": {
              "locationType": "string",
              "idOrCode": "string",
              "name": "string",
              "country": {
                "idOrCode": "string",
                "name": "string",
                "telephonePrefix": [
                  "string"
                ]
              },
              "latitude": 0,
              "longitude": 0,
              "locationLanguages": [
                {
                  "id": 0,
                  "location": {},
                  "language": {
                    "id": 0,
                    "code": "string",
                    "name": "string"
                  },
                  "name": "string",
                  "channel": "string",
                  "displayOrder": 0
                }
              ],
              "areas": [
                {
                  "idOrCode": "string",
                  "name": "string"
                }
              ]
            },
            "destinations": [
              {
                "locationType": "string",
                "idOrCode": "string",
                "name": "string",
                "country": {
                  "idOrCode": "string",
                  "name": "string",
                  "telephonePrefix": [
                    "string"
                  ]
                },
                "latitude": 0,
                "longitude": 0,
                "locationLanguages": [
                  {
                    "id": 0,
                    "location": {},
                    "language": {
                      "id": 0,
                      "code": "string",
                      "name": "string"
                    },
                    "name": "string",
                    "channel": "string",
                    "displayOrder": 0
                  }
                ],
                "areas": [
                  {
                    "idOrCode": "string",
                    "name": "string"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
 */
export const apiRoutes = ({ transportationType, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/routes',
    headers: {...token,  "language-code": $nuxt.$i18n.locale},
    params: {
      transportationType
    }
  }).then(res => res.data)

/**
 * Get all routes of type sea
 * @param { Object } params - Params
 * @param { String } params.token - Access token string
 * @returns { Promise }
 */
export const apiHarbourRoutes = params => apiRoutes({ ...params, transportationType: 'ST' })

/**
 * This method fetches all the possible routes from a selected Origin.
 * The Origin is given in the SearchText parameter and can be either the Location Code or Description.
 * The response of the method, is a list of “Transportation” entities, in JSON format.
 *
 * @param { Object } params - Params
 * @param { String } params.transportationType - Possible values are HARBOUR, GENERIC_LOCATION, BUS_STOP etc.
 * @param { String } params.searchText - The Origin Location To Search for. Can be either Location Code or Description.
 * @param { String } params.destination - The Destination Location To Search for. Can be either Location Code or Description.
 * @param { String } params.token - Access token string
 * Example response: [
    {
      "locationType": "string",
      "idOrCode": "string",
      "name": "string",
      "country": {
        "idOrCode": "string",
        "name": "string",
        "telephonePrefix": [
          "string"
        ]
      },
      "latitude": 0,
      "longitude": 0,
      "locationLanguages": [
        {
          "id": 0,
          "location": {},
          "language": {
            "id": 0,
            "code": "string",
            "name": "string"
          },
          "name": "string",
          "channel": "string",
          "displayOrder": 0
        }
      ],
      "areas": [
        {
          "idOrCode": "string",
          "name": "string"
        }
      ]
    }
  ]
 */
export const apiRoutesWithFilter = ({ transportationType, searchText, includeConjunctions, destination, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/routes-with-filter',
    headers: token,
    params: {
      transportationType,
      searchText,
      includeConjunctions,
      destination
    }
  }).then(res => res.data)

/**
 * Get harbour routes with filter
 *
 * @param { Object } params
 * @param { String } params.searchText - The Origin Location To Search for. Can be either Location Code or Description.
 * @param { String } params.destination - The Destination Location To Search for. Can be either Location Code or Description.
 * @param { String } params.token - Access token string
 * @returns { Promise }
 */
export const apiHarbourRoutesWithFilter = params => apiRoutesWithFilter({ ...params, transportationType: 'HARBOUR' })

export const RouteFrequencyRequestBodyDTO = ({
  fetchCompanies = true,
  origin,
  destination,
  departureDate,
  includeConjunctions,
  companies = []
}) => {
  departureDate = moment.isMoment(departureDate)
    ? departureDate.format('YYYY-MM-DD')
    : moment(departureDate).format('YYYY-MM-DD')
  return {
    fetchCompanies,
    origin, // : {
    //   locationType: 'string',
    //   idOrCode: 'string',
    //   name: 'string'
    // },
    destination, // : {
    //   locationType: 'string',
    //   idOrCode: 'string',
    //   name: 'string'
    // },
    includeConjunctions,
    departureDate,
    companies // [
    // {
    //   id: 0,
    //   abbreviation: 'string',
    //   description: 'string',
    //   imageUrl: 'string',
    //   siteUrl: 'string'
    // }
    // ]
  }
}

export const RouteFrequencyWithProjectRequestBodyDTO = ({
  fetchCompany = false,
  origin,
  destination,
  departureDate,
}) => {
  departureDate = moment.isMoment(departureDate)
    ? departureDate.format('YYYY-MM-DD')
    : moment(departureDate).format('YYYY-MM-DD')
  return {
    fetchCompany,
    origin,
    destination,
    departureDate,
  }
}

export const apiRouteFrequency = ({ project, originIdOrCode, destinationIdOrCode, departureDate, token, includeConjunctions }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/route-frequency${project ? '/' + project : ''}`,
    headers: token,
    data: project
      ? RouteFrequencyWithProjectRequestBodyDTO({
        origin: { idOrCode: originIdOrCode },
        destination: { idOrCode: destinationIdOrCode },
        departureDate,
      })
      : RouteFrequencyRequestBodyDTO({
        fetchCompany: !Boolean(project),
        origin: { idOrCode: originIdOrCode },
        destination: { idOrCode: destinationIdOrCode },
        departureDate,
        // includeConjunctions
      })
  }).then(res =>{ return res.data})
