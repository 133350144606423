import axios from '../../client/axios'
import { BASE_URL_API_GUI } from '../../settings'

/**
 * This method get the printing template of reservation
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/index.html
 * @param { Object } data
 * @param { Object } data.params - Query params
 * @param { String | Number } data.params.reservationId - Reservation id
 * @param { String } data.params.printerName - Printer name (Required)
 * @param { String } data.params.ticketNumbers - Ticket numbers
 * @response [
 *      {
 *          Template: string
 *      }
 *  ]
 */
export const apiPrintingTemplate = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/printing-template',
    headers: { Authorization: `Bearer ${token}` },
    params
  }).then(res => res.data)
