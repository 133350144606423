import _get from 'lodash/get'
import axios from '../../client/axios'
import _groupBy from 'lodash/groupBy'
import _difference from 'lodash/difference'
import { BASE_URL_API_GUI } from '../../settings'
export const discountIdOrCode = accommodation => {
  return _get(accommodation, 'pricing_attribute_discountidorcode.selectedValue', undefined)
}
export const discountDocument = accommodation => {
  return _get(accommodation, 'pricing_attribute_discountdocument.selectedValue', undefined)
}
export const loyaltyCardAttr = accommodation => {
  return _get(accommodation, 'pricing_attribute_loyaltycard.selectedValue', undefined)
}
const attrs = selectedPassengerOrVehicle => {
  return Object.entries(selectedPassengerOrVehicle)
  .filter(([key, value]) => key !== "pricing_attribute_discountidorcode" && key !== 'pricing_attribute_loyaltycard')
  .filter(([key, value]) => key.includes('pricing_attribute') && value && typeof value.selectedValue !== 'undefined')
  .reduce((obj, [key, value]) => {
    return {
      ...obj,
      [value.fieldName]: value.selectedValue,
    };
  }, {})
}
const accommodationType = (selectedPassenger) => {
  if(selectedPassenger.pricing_attribute_sextype?.selectedValue) return `${selectedPassenger.pricing_attribute_sextype?.selectedValue}_BERTH`
}

const VehicleAccomodationRequestDTO = selectedVehicle => {
  return {
    idOrCode: selectedVehicle?.accommodationType?.accommodation?.idOrCode,
    accommodationRequestType: 'VEHICLE',
        quantity: 1,
    accommodationRequestAnalysises: [
      {
        index: 1,
        discountDocument: discountDocument(selectedVehicle),
        discountIdOrCode: discountIdOrCode(selectedVehicle),
        loyaltyCard: loyaltyCardAttr(selectedVehicle),
        vehicleData: { 
          ...attrs(selectedVehicle), 
          discountDocument: undefined, 
          discount: undefined 
        }
      }
    ]
  }
}
const PassengerAccomodationRequestDTO = (selectedPassenger, passGroup, prices, usedAccommodationNumbers) => {
  const passIncludedInGroup = (pass) => passGroup && passGroup.length && passGroup.includes(pass);

  // Βρίσκουμε το επόμενο διαθέσιμο accommodationNumber για το επιλεγμένο passenger
  const itemIdOrCode = selectedPassenger?.accommodationType?.accommodation?.idOrCode;
  const priceEntries = prices.filter(price => price && price?.itemIdOrCode === itemIdOrCode);
  const currentIndex = usedAccommodationNumbers[itemIdOrCode] || 0;
  const accommodationNumber = priceEntries[currentIndex]?.accommodationNumber || '';

  // Ενημερώνουμε τον δείκτη του usedAccommodationNumbers για το επόμενο accommodationNumber
  usedAccommodationNumbers[itemIdOrCode] = currentIndex + 1;

  const result = {
    idOrCode: itemIdOrCode,
    accommodationRequestType: passIncludedInGroup(selectedPassenger) ? 'COMPLETE' : accommodationType(selectedPassenger),
    bedType: 'NO_PREFERENCE',
    ...(selectedPassenger?.accommodationType?.fareCode && {fareCode: selectedPassenger?.accommodationType?.fareCode}),
    quantity: 1,
    accommodationRequestAnalysises: [
      {
        index: 1,
        discountIdOrCode: discountIdOrCode(selectedPassenger),
        loyaltyCard: loyaltyCardAttr(selectedPassenger),
        passengerData: {
          ...attrs(selectedPassenger),
          discount: undefined,
          accommodationNumber: accommodationNumber
        },
        uuid: selectedPassenger.uuid
      }
    ]
  };

  return result;
}

const PassengerAccomodationRequestDTOComplete = (selectedPassengers, passGroup, group ) => {
  return {
    idOrCode: selectedPassengers[0]?.accommodationType?.accommodation?.idOrCode, 
    accommodationRequestType: 'COMPLETE',
    bedType: 'NO_PREFERENCE',
    ...(selectedPassengers[0]?.accommodationType?.fareCode && {fareCode: selectedPassengers[0]?.accommodationType?.fareCode}),
    quantity: 1,
    accommodationRequestAnalysises: selectedPassengers.map((p) => {
      const groupIndex = passGroup.findIndex((group) => group.includes(p));
      return {
        index: groupIndex ,
        discountIdOrCode: discountIdOrCode(p),
        loyaltyCard: loyaltyCardAttr(p),
        passengerData: {...attrs(p), accommodationNumber: _get(p, 'selectedSeat.accommodationNumber', '')}
      }
    })
  };
};

const mergeItemsWithSameId = (items) => {
  const mergedItems = [];
  const idOrCodeMap = new Map();

  for (const item of items) {
    const existingItem = idOrCodeMap.get(item.idOrCode);
    if (existingItem && existingItem.accommodationRequestType === item.accommodationRequestType) {
      // Item with the same idOrCode and accommodationRequestType already exists, increase quantity
      existingItem.quantity += item.quantity;
      // Append the analysises from the current item to the existing item
      existingItem.accommodationRequestAnalysises.push(...item.accommodationRequestAnalysises);
    } else {
      // No item with the same idOrCode and accommodationRequestType, add it to the map
      idOrCodeMap.set(item.idOrCode, item);
      mergedItems.push(item);
    }
  }

  return mergedItems;
}


const TripOptionsDTONotSort = (trip, companies, hasExtraServices, planItems) => {
  const filteredPassengers = Object.values(trip.selectedPassengers).filter(p => p.passIndex !== 11111);
  const passGroup = Object.values(_groupBy(filteredPassengers, 'passIndex')).map(p => p.filter(a => p.every(i => i.passIndex !== undefined)));
   
  const completeCabin = passGroup.map(g => {
    const tripCompany = trip.vessel.company.abbreviation
    const accIdOrCode = g[0]?.accommodationType.accommodation?.idOrCode
    const cabinCapacity = companies[tripCompany].accommodations.passengers[accIdOrCode]?.capacity
    return g?.length <= cabinCapacity
  })

  const group = completeCabin.map((a, i) => a && passGroup[i]).flat().filter(el => Object.keys(el).length);
  const notCompletePass = _difference(Object.values(trip.selectedPassengers || {}), group);
  let accommodationRequests = [];

  // Χρησιμοποιούμε ένα αντικείμενο για να παρακολουθούμε τα usedAccommodationNumbers
  const usedAccommodationNumbers = {};

  accommodationRequests = Object.values(_groupBy(group, 'passIndex') || {})
    .map((pass) => {
      return pass.length > 1 || pass[0].accommodationType.accommodation.exclusiveUse
        ? PassengerAccomodationRequestDTOComplete(pass, passGroup, group)
        : PassengerAccomodationRequestDTO(pass[0], passGroup, trip.prices, usedAccommodationNumbers);
    })
    .concat(trip.selectedVehicles && Object.values(trip.selectedVehicles).map(VehicleAccomodationRequestDTO));

  if (notCompletePass.length) {
    accommodationRequests = [...accommodationRequests, ...notCompletePass.map(ncp => PassengerAccomodationRequestDTO(ncp, null, trip.prices, usedAccommodationNumbers))];
  }

  // Ταξινόμηση των στοιχείων του accommodationRequests βάσει του πεδίου accommodationRequestType
  accommodationRequests.sort((a, b) => {
    const typeA = a.accommodationRequestType.toUpperCase();
    const typeB = b.accommodationRequestType.toUpperCase();
    if (typeA < typeB) {
      return -1;
    }
    if (typeA > typeB) {
      return 1;
    }
    return 0;
  });

  let mergedAccommodationRequests = mergeItemsWithSameId(accommodationRequests);
  // Ενημερώνουμε τη λίστα accommodationRequestAnalysises με τα δεδομένα από όλα τα αιτήματα διαμονής
  const updatedAccommodationRequests = mergedAccommodationRequests.map(request => {
    const analysises = mergedAccommodationRequests
      .filter(r => r.idOrCode === request.idOrCode && r.accommodationRequestType === request.accommodationRequestType)
      .map(r => r.accommodationRequestAnalysises)
      .flat();
    request.accommodationRequestAnalysises = analysises;
    return request;
  });

  mergedAccommodationRequests = updatedAccommodationRequests;
  
  let counter = 0;
  let accommodationsCounter = 0;

  mergedAccommodationRequests.forEach((accommodation) => {
    accommodationsCounter += accommodation.accommodationRequestAnalysises.length
  });

  mergedAccommodationRequests.forEach((accommodation) => {
    accommodation.accommodationRequestAnalysises.forEach((element) => {
      if (planItems && planItems[counter]) {
        if (accommodationsCounter >= planItems.length) {
          if (element.passengerData) {
            const selectedSeatAccommodationNumber =
              trip.selectedPassengers[element.passengerData.uuid]?.selectedSeat?.accommodationNumber;
  
            element.passengerData.accommodationNumber =
              selectedSeatAccommodationNumber || planItems[counter]?.accommodationNumber || "";
            counter++;
          } else if (element.vehicleData && planItems[counter].accommodation.priceAccommodationType === "VEHICLE") {
            element.vehicleData.priorityNumber = planItems[counter]?.accommodationNumber || "";
            counter++;
          }
        } else {
          do {
            if (element.passengerData ) {
              const selectedSeatAccommodationNumber =
                trip.selectedPassengers[element.passengerData.uuid]?.selectedSeat?.accommodationNumber;
  
              element.passengerData.accommodationNumber =
                selectedSeatAccommodationNumber || planItems[counter]?.accommodationNumber || "";
              counter++;
              break;
            } else if (element.vehicleData && planItems[counter].accommodation.priceAccommodationType === "VEHICLE") {
              element.vehicleData.priorityNumber = planItems[counter]?.accommodationNumber || "";
              counter++;
              break;
            }
            counter++;
          } while (counter < planItems.length)
        }
      }
    })
  });

  const updateIndexesForQuantityOne = (accommodationRequests) => {
    accommodationRequests.forEach((request) => {
      if (request.quantity === 1) {
        request.accommodationRequestAnalysises.forEach((analysis) => {
          analysis.index = 1;
        });
      } else {
        const numPassengers = request.accommodationRequestAnalysises.length;
        const indexIncrement = Math.ceil(numPassengers / request.quantity);
        let currentIndex = 1;

        request.accommodationRequestAnalysises.forEach((analysis, analysisIndex) => {
          analysis.index = currentIndex;
          if ((analysisIndex + 1) % indexIncrement === 0) {
            currentIndex++;
          }
        });
      }
    });
  };

  updateIndexesForQuantityOne(mergedAccommodationRequests);

  return {
    alternateAgencyCode: trip.alternateAgencyCode,
    departureDateTime: trip.departureDateTime,
    origin: {
      idOrCode: trip.origin.idOrCode
    },
    destination: {
      idOrCode: trip.destination.idOrCode
    },
    vessel: { ...trip.vessel, company: { ...trip.vessel.company, id: 161 } },
    accommodationRequests: mergedAccommodationRequests.filter(ac => ac && Object.keys(ac).length),
    additionalServices: AdditionalServicesDTO(trip.additionalServices || [], hasExtraServices)
  }
}


const TripOptionsDTO = (trip, companies) => {
  const passGroup = Object.values(_groupBy(trip.selectedPassengers, 'passIndex')).map(p => p.filter(a => p.every(i => i.passIndex !== undefined)))
  const completeCabin = passGroup.map(g => {
    const tripCompany = trip.vessel.company.abbreviation
    const accIdOrCode = g[0]?.accommodationType.accommodation?.idOrCode
    const cabinCapacity = companies[tripCompany].accommodations.passengers[accIdOrCode]?.capacity
    return g?.length <= cabinCapacity
  })
  const group = completeCabin.map((a, i) => a && passGroup[i]).flat().filter(el => Object.keys(el).length)
  const notCompletePass = _difference(Object.values(trip.selectedPassengers || {}), group)
  let accommodationRequests = []
  // if(group.length){
  accommodationRequests = Object.values(_groupBy(group, 'passIndex') || {})
    .map((pass) => {
      return PassengerAccomodationRequestDTOComplete(pass)
    })
    .concat(trip.selectedVehicles && Object.values(trip.selectedVehicles).map(VehicleAccomodationRequestDTO))
    if(notCompletePass.length){
      accommodationRequests = [...accommodationRequests, ...notCompletePass.map(ncp => PassengerAccomodationRequestDTO(ncp))]
      accommodationRequests.sort(function (a, b) {
        return Object.values(trip.selectedPassengers || {}).map(p => p.accommodationType?.accommodation?.idOrCode).indexOf(a.idOrCode) - Object.values(trip.selectedPassengers).map(p => p.accommodationType?.accommodation?.idOrCode).indexOf(b.idOrCode);
      })
    }
  return {
    alternateAgencyCode: trip.alternateAgencyCode,
    departureDateTime: trip.departureDateTime,
    origin: {
      idOrCode: trip.origin.idOrCode
    },
    destination: {
      idOrCode: trip.destination.idOrCode
    },
    vessel: {...trip.vessel, company: {...trip.vessel.company, id: 161}},
    accommodationRequests: accommodationRequests.filter(ac => ac && Object.keys(ac).length),
    additionalServices: AdditionalServicesDTO(trip.additionalServices || [])
  }
}

export const AdditionalServicesDTO = (additionalServices, hasExtraServices) => {
  if (additionalServices && additionalServices?.length > 0) {
    const addServices = additionalServices.map(service => ({
      ...service,
      uniqueId: undefined,
      items: service?.items.map(i => ({
        ...i, 
        availableUnits: i.selAvailableUnits !== undefined ? i.selAvailableUnits : i.availableUnits, 
        selAvailableUnits: undefined
      })).filter(o => o.availableUnits >= 0) || []
    }));
  
    return addServices.every(s => s.items.length === 0) ? undefined : addServices;
  } else {
    return [];
  }
}

export const PricingRequestBodyDTO = ({ trips, recall, isGroup, fareIdOrCode, keepSeats, openReturn, companies }) => {
  return {
    trips: recall ? trips : trips.map(trip => TripOptionsDTO(trip, companies)),
    fareIdOrCode,
    openReturn,
    keepSeats
  }
}

export const PricingRequestBodyDTONotSort = ({ trips, recall, isGroup,  keepSeats, openReturn, companies, hasExtraServices, farePerCompanies ,projectCode ,  voucherCode}) => {
  return {
    trips: recall ? trips : trips.map(trip => TripOptionsDTONotSort(trip,  companies, hasExtraServices)),
    openReturn,
    keepSeats,
    projectCode,
    voucherCode,
    ...(farePerCompanies && {farePerCompanies: farePerCompanies})
  };
};

export const unlockRequestBodyDTONotSort = ({ trips, recall, isGroup, planItems, keepSeats, openReturn, companies, hasExtraServices }) => {
  return {
    trips: recall ? trips : trips.map(trip => TripOptionsDTONotSort(trip, companies, hasExtraServices, planItems)),
    openReturn,
    keepSeats,
  };
};

// CONJUCTIONS
const ConjuctionsPassengerAccomodationRequestDTO = (selectedPassenger, conTrip, i) => {
  return {
    idOrCode: selectedPassenger?.accommodationType?.accommodation?.idOrCode, 
    accommodationRequestType: 'MALE_BERTH',
    bedType: 'NO_PREFERENCE',
    quantity: 1,
    originId: conTrip.origin.idOrCode,
    destinationId: conTrip.destination.idOrCode,
    accommodationRequestAnalysises: [
      {
        index: i + 1,
        discountIdOrCode: discountIdOrCode(selectedPassenger),
        passengerData: {
          attributes: attrs(selectedPassenger),
          ticket: {
            letter: selectedPassenger?.openTicket?.letter,
            number: selectedPassenger?.openTicket?.number
          }
        }
      }
    ]
  }
}

const ConjuctionsTripOptionsDTO = (trip, conTrips) => {
  const relatedConjuctionsTrips = conTrips.filter(
    t =>
      t.isConjuctionFrom ===
      trip.origin.idOrCode + trip.destination.idOrCode + trip.departureDateTime + trip.vessel.idOrCode
  )

  const accommodationRequests = relatedConjuctionsTrips
    .map(conTrip =>
      Object.values(conTrip.selectedPassengers)
        .map((selectedPassenger, i) => ConjuctionsPassengerAccomodationRequestDTO(selectedPassenger, conTrip, i))
        .concat(trip.selectedVehicles && Object.values(trip.selectedVehicles).map(VehicleAccomodationRequestDTO))
    )
    .flat()
  return {
    departureDateTime: trip.departureDateTime,
    origin: {
      idOrCode: trip.origin.idOrCode
    },
    destination: {
      idOrCode: trip.destination.idOrCode
    },
    accommodationRequests,
    additionalServices: []
    // additionalServices: AdditionalServicesDTO(trip.additionalServices || [])
  }
}

export const ConjuctionsPricingRequestBodyDTO = ({ trips, conTrips }) => {
  return {
    trips: trips
      .concat(conTrips.filter(t => !t.isConjuction))
      .map(trip =>
        trip.intermediateStops.length === 1 ? TripOptionsDTO(trip) : ConjuctionsTripOptionsDTO(trip, conTrips)
      )
  }
}

export const apiPricing = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/multi-pricing',
    headers: token,
    data
  }).then(res => {
    let response = res.data
    if (data.openReturn) {
      const trip = res.data.trips.slice(0)[0]
      const prices = res.data.trips.map(t => t.prices).flat()
      response = {...res.data, trips: [{...trip, prices: prices}]}
    }
    if (!data.keepSeats) {
      response.trips?.forEach(trip => trip.prices?.forEach(price => price.accommodationNumber = undefined))
    }
    return response
  })
