<template>
  <div class="whats-new-container">
    <div class="window" v-shortkey="['esc']" @shortkey="closeWhatsNewModal">
      <div class="top-container"></div>
      <div class="liknoss-logo"></div>
      <div class="bottom-container">
        <div v-html="contentToShow"></div>
        <button @click="closeWhatsNewModal" style="font-size: 30px;">X</button>
      </div>
    </div>
  </div>
</template>

<script>
import newVersionData from '../assets/js/newVersionData';

export default {
  props: {
    closeWhatsNewModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      htmlData: newVersionData,
    };
  },
  computed: {
    contentToShow() {
      if ($nuxt.$i18n.locale === 'el') {
        return this.htmlData.find((data) => data.el).el;
      } else {
        return this.htmlData.find((data) => data.en).en;
      }
    },
  },
};
</script>

<style scoped>
.whats-new-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.window {
  width: 35vw;
  height: 80vh;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  border: 2px solid var(--main-color);
}

.liknoss-logo {
  height: 100px;
  width: 100px;
  border: 4px solid var(--main-color);
  border-radius: 50px;
  background-image: url("../assets/img/logo_AgencyX.svg");
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.top-container {
  height: 30%;
  width: 100%;
  background-image: url('../assets/img/naxos_cover.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bottom-container {
  height: 70%;
  width: 100%;
  padding: 20px;
  overflow: auto
}

h2 {
  font-size: 20px;
  margin: 10px 0;
}

p {
  font-size: 14px;
  margin: 10px 0;
}

button {
  position: absolute;
  top: -55px;
  right: -50px;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}
</style>