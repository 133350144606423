import { message } from 'ant-design-vue';
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import { createStorage } from '@/helpers/storage'
import pkg from '../../../package.json'
import $axios from 'axios'
import { BASE_URL_API_GUI } from '../../../src/renderer/api/settings'

export default function ({store,redirect}) {

    // Response interceptor for API calls
    let conf='';
    $axios.interceptors.response.use( async (response) => {

        if( _get(response, 'data.code', '') === '058' ||  _get(response, 'data.code', '')  === Number('058')  ){

            const storage = createStorage(localStorage)
            if(response.config.url!== "/token"){
                conf = response.config
            }

            const loginData = storage.get('loginData', {})

            let username = store.state.user.session.username;
            let password =  store.state.user.session.password;
            let agencyCode =  store.state.user.session.agencyCode;
            let agencySignature =  null;
            let validation_code =  store.state.user.session.validation_code;
            let validation_code_expiry_in_minutes =  loginData['validation_code_expiry_in_minutes']

            const newInstance = $axios.create()
            return newInstance({
                method: 'GET',
                baseURL: BASE_URL_API_GUI,
                url: process.env.API_BASE_PATH_GUI + '/token',
                headers: {
                  'Content-Type': 'application/json',
                  'agency-code': agencyCode || '1300',
                  'agency-user-name': username,
                  'agency-password': password,
                  "language-code": $nuxt.$i18n.locale,
                  "agency-x-major-version": pkg['major-version'],
                  "agency-x-minor-version": pkg['minor-version'],
                  "agency-x-version": pkg['major-version'] + '.' + pkg['minor-version'] + '.' + pkg['patch-version'],
                  "agency-x-env":  process.env.ENVIRONMENT,
                  "validation-code": validation_code || '',
                  "validation-code-expiry-in-minutes": validation_code_expiry_in_minutes || ''
                }
              }).then( async res => {

                let data = res.data;

                store.commit('user/SET_TOKEN',{...data, username, password, agencyCode, agencySignature,validation_code})

                store.commit('user/SET_SESSION',{...data, username, password, agencyCode, agencySignature,validation_code})

                conf.headers.token=data.token;
            
                if(_get(res, 'data.code', '') === '000' || _get(res, 'data.code', '') === '051') {
                  return $axios(conf);
                } else {
                  $nuxt.$options.router.push({ path: '/' })
                }

            }).catch(error => console.log(error) ); 

        } else {
            return response;
        }
  }, async function (error) {

    const originalRequest = error.config;

    if (error && error.response && error.response.status && error.response.status === 401 ) {
      const run = () => message.error('Session has expired. Please Login again')
      const notification = _debounce(run, 500);
      notification()
      setTimeout(() => {
        console.log(redirect);
        $nuxt.$options.router.push({ path: '/' })
      },1000)
    }

    return Promise.reject(error);

  });
}