const newVersionData = [ 
{ en: `
<!-- 119.5.0 -->
<h2 style="font-weight: bold; text-align: center;"> Version 119.5.0 </h2>
<ul>
  <li>
    <strong>New Features:</strong>
    <ul>
      <li>
        <p>Added extra information in the booking confirmation.</p>
      </li>
      <li>
        <p>Restored the ability to view release notes directly within the user interface.</p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Bug Fixes:</strong>
    <ul>
      <li>
        <p>Corrected inaccuracies in price analysis calculations.</p>
      </li>
      <li>
        <p>Resolved issues with system calls occurring after the print draft is displayed.</p>
      </li>
      <li>
        <p>Fixed a problem where the language setting was not being shown correctly in the settings.</p>
      </li>
      <li>
        <p>Addressed an issue where the system was not properly considering location areas during availability calls.</p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.4.0 -->
<h2 style="font-weight: bold; text-align: center;"> Version 119.4.0 </h2>
<ul>
  <li>
    <strong>New Features:</strong>
    <ul>
      <li>
        <p>Add confirmation before issuance as an option in settings.</p>
      </li>
      <li>
        <p>Booking confirmation notes for Viking bookings.</p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Bug Fixes:</strong>
    <ul>
      <li>
        <p>Issues with unlocking seats selected in the seat plan have been resolved.</p>
      </li>
      <li>
        <p>An issue with shortcuts triggering Google Chrome's shortcuts has been fixed.</p>
      </li>
      <li>
        <p>An issue with vehicles section being shown when there's no garage has been resolved.</p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Improvements:</strong>
    <ul>
      <li>
        <p>Improved the seat plan UI/UX.</p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.3.0 -->
<h2 style="font-weight: bold; text-align: center;"> Version 119.3.0 </h2>
<ul>
  <li>
    <strong>Bug Fixes:</strong>
    <ul>
      <li>
        <p>Fixed an issue with copying vehicle data.</p>
      </li>
      <li>
        <p>Addressed network call issues for specific AgencyX users.</p>
      </li>
      <li>
        <p>Improved recall reservation error messages.</p>
      </li>
      <li>
        <p>Fixed an error regarding loyalty card validation.</p>
      </li>
      <li>
        <p>Corrected a cancellation fees literal.</p>
      </li>
      <li>
        <p>Resolved some booking transfer flow issues.</p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.2.0 -->
<h2 style="font-weight: bold; text-align: center;"> Version 119.2.0 </h2>
<ul>
  <li>
    <strong>Improvements:</strong>
    <ul>
      <li>
        <p>
          Changes to ATC thermal tickets
        </p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.1.0 -->
<h2 style="font-weight: bold; text-align: center;"> Version 119.1.0 </h2>
<ul>
  <li>
    <strong>Bug Fixes:</strong>
    <ul>
      <li>
        <p>
          Corrected an issue where vehicle discounts were not being applied accurately.
        </p>
      </li>
      <li>
        <p>Fixed a bug that prevented updates of ERP data within the application.</p>
      </li>
      <li>
        <p>
          Resolved an issue that hindered the display of company names in step 1.
        </p>
      </li>
      <li>
        <p>
          Addressed several issues that overal improved the application's ux in step 1.
        </p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 119.0.0</h2>
<ul>
  <li>
    <strong>Improvements:</strong>
    <ul>
      <li>
        <p>
          The login screen has been redesigned with a fresh, modern look.
        </p>
      </li>
      <li>
        <p>
          Desktop app updates have been improved in this version.
        </p>
      </li>
      <li>
        <p>
          Improved ERP integration.
        </p>
      </li>
      <li>
        <p>
          Implemented specific changes for Spanish residents.
        </p>
      </li>
      <li>
        <p>
          Improved dates slider functionality in Step 1.
        </p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Bug Fixes:</strong>
    <ul>
      <li>
        <p>
          An issue related to recalling reservations by company code has been
          resolved.
        </p>
      </li>
      <li>
        <p>Fixed a bug that prevented accurate search results for trips on the current day.</p>
      </li>
      <li>
        <p>
          Resolved a bug that prevented accurate data copying in round-trip reservations.
        </p>
      </li>
      <li>
        <p>
          Addressed an issue that caused errors during ticket cancellations and subsequent ERP updates.
        </p>
      </li>
      <li>
        <p>Fixed passenger data copy issue with F4.</p>
      </li>
      <li>
        <p>Trip selection in step 1 is now based on reservation type.</p>
      </li>
      <li>
        <p>The issue preventing correct pricing and seat information display in step 2 has been resolved.</p>
      </li>
      <li>
        <p>The problem that prevented the thermal printing of tickets from the recall screen has been resolved.</p>
      </li>
      <li>
        <p>Bugs related to pet prices in availability screen have been fixed.</p>
      </li>
      <li>
        <p>The error that prevented the unlock of selected seats during logout has been fixed.</p>
      </li>
      <li>
        <p>Searching for port locations with greek characters is now working as expected.</p>
      </li>
      <li>
        <p>The error related to email & phone validation has been fixed.</p>
      </li>
      <li>
        <p>Fixed trip display issues in transfer screen.</p>
      </li>
    </ul>
  </li>
</ul>


<!-- 118.5.1 -->
<h2 style="font-weight: bold; text-align: center;">Version 118.5.1</h2>
<ul>
    <li>
        <strong>Bug Fixes:</strong>
        <ul>
            <p>
                Resolved a problem that was blocking the booking of Black Friday offers for a particular company.
            </p>
        </ul>
    </li>   
</ul>

<!-- 118.5.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 118.5.0</h2>
<ul>
  <li>
    <strong>New Features:</strong>
    <ul>
      <li>
        <p>
          This update introduces new validation attributes while making a booking.
        </p>
      </li>
      <li>
        <p>
          Sentry.io has been integrated for improved error tracking and application performance.
        </p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Bug Fixes:</strong>
    <ul>
      <li>
        <p>
          An issue related to the issuance optional multi-segment reservations has been
          resolved.
        </p>
      </li>
      <li>
        <p>Fixed an issue with vehicle ticket replacements.</p>
      </li>
      <li>
        <p>
          The bug that prevented pets from being sent in pricing requests
          for a specific company has been addressed.
        </p>
      </li>
      <li>
        <p>
          Resolved an issue encountered during the convertion of tickets to open.
        </p>
      </li>
      <li>
        <p>A bug affecting the app's statistics has been fixed.</p>
      </li>
      <li>
        <p>The bug affecting the company dropdown in step 1 has been fixed.</p>
      </li>
      <li>
        <p>The issue preventing full cabin selection for round-trip reservations has been resolved.</p>
      </li>
      <li>
        <p>The functionality to preview and print bookings for companies issuing e-tickets has been added.</p>
      </li>
      <li>
        <p>Bugs related to seat selection on the detailed availability tab have been fixed.</p>
      </li>
      <li>
        <p>The error that prevented the selection and preview of a booking on the retrieval screen has been fixed.</p>
      </li>
      <li>
        <p>Selecting extra services on the retrieval tab is now working as expected.</p>
      </li>
      <li>
        <p>The error that was duplicating the selected seat in Step 2 has been fixed.</p>
      </li>
      <li>
        <p>The fast availability display and calculation have been optimized.</p>
      </li>
    </ul>
  </li>
</ul>



<!-- 118.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 118.0.0</h2>
<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p><strong>ATC Cancellation Fees</strong></p>
                <p>View detailed information about cancellations for ATC bookings. To find this option, open the app's right-hand menu and then select "ATC Cancellation Fees".</p>
            </li>
            <li>
                <p><strong>Desktop app users can now use the application without administrator rights.</strong></p>
            </li>
        </ul>
    </li>   
</ul>

<!-- 117.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 117.0.0</h2>

<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p><strong>Pet and Extra Service Ticket Replacement & Conversion to Open</strong></p>
                <p>You can now replace tickets for pets and extra services and change their status to open for supported companies.</p>
            </li>
            <li>
                <p><strong>Users will be automatically logged out after 1 hour of inactivity.</strong></p>
            </li>
        </ul>
    </li>
</ul>

<!-- 116.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 116.0.0</h2>

<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p><strong>Added Statistics Module</strong></p>
                <p>Now you can view analytical sales statistics through the statistics module, using the available search filters. To navigate to the application, open the right menu and then select "Statistics."</p>
            </li>   
            <li>
                <p><strong>Environmental tax added to thermal tickets for supported companies.</strong></p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Improvements:</strong>
        <ul>
            <li>
                <p><strong>Added seat number for each ticket in reservation recall and reservation details printout.</strong></p>
            </li>
        </ul>
    </li>
</ul>
    
<!-- 115.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 115.0.0</h2>

<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p><strong>Points Redemption with Seasmiles Cards from Attica Group</strong></p>
                <p>Now you can redeem the points accumulated on a Seasmiles card through our application, in an easy and user-friendly way.</p>
            </li>   
            <li>
                <p><strong>Fuel type for vehicles has been added to supported companies.</strong></p>
            </li>  
        </ul>
    </li>
    <li>
        <strong>Improvements:</strong>
        <ul>
            <li>
                <p>In Step 2, the user experience during data entry has been enhanced, and you can now use the arrow keys on the keyboard or press Enter to switch fields.</p>
            </li>
            <li>
                <p>Improvements have been made to the copy function with F4.</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 114.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 114.0.0</h2>

<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p><strong>Discount codes for Vodafone CU & COSMOTE DEALS for YOU</strong></p>
                <p>Now you can make reservations by redeeming Vodafone CU & COSMOTE DEALS for YOU codes through the application, in an easy and user-friendly way.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Improvements:</strong>
        <ul>
            <li>
                <p>Improvements have been made to the appearance and functionality of the "Tip of the Day" window.</p>
            </li>
            <li>
                <p>The email of the reservation has been added to the recall screen and print draft.</p>
            </li>
            <li>
                <p>The "Document Number" field has been added to vehicles so that discounts can be applied to companies that support it.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Bug Fixes:</strong>
        <ul>
            <li>
                <p>Fixes have been made to the appearance of the pricing window.</p>
            </li>
            <li>
                <p>Improvements have been made to ticket replacements, search filters in Step 1, and itineraries sorting.</p>
            </li>
            <li>
                <p>The issue with searching itineraries from the date slider, where not all results were displayed, has been fixed.</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 113.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 113.0.0</h2>

<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p>Right-clicking on any screen now displays available shortcuts that can be executed.</p>
            </li>
            <li>
                <p>In ferry route search results, the company logo and photo of each ship (where available) have been added.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Improvements:</strong>
        <ul>
            <li>
                <p>Enhancements have been made to the appearance and functionality of extra services.</p>
            </li>
            <li>
                <p>In the ferry route search, the time selector has been modified to allow searching every 5 minutes.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Bug Fixes:</strong>
        <ul>
            <li>
                <p>Fixed an issue where, during the issuance of an optional reservation with fare quotation, the discount was not applied.</p>
            </li>
            <li>
                <p>Fixed the problem that occurred during the issuance of a reservation with an unaccompanied vehicle.</p>
            </li>
            <li>
                <p>Corrections have been made to the display of financial data.</p>
            </li>
            <li>
                <p>Fixed the issue where the application would change language when transitioning from one screen to another.</p>
            </li>
            <li>
                <p>Fixed the problem with aller-retour (round trip) reservations, where transitioning from the first trip to the second would display the wrong total amount for the second trip.</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 112.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 112.0.0</h2>

<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p>Pets: Ticket issuance with pets is now supported. You can add pets to a booking from the extra services section in Step 2.</p>
            </li>
            <li>
                <p>Tips: Upon launching the application, helpful tips will now be displayed to improve the experience you have with the application.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Improvements:</strong>
        <ul>
            <li>
                <p>Success, error, and warning messages can now remain on the screen until manually closed. You can enable this option from the settings screen.</p>
            </li>
            <li>
                <p>Enhancements to the appearance of the availability screen. Additional information about class accommodations in companies that support it has also been added.</p>
            </li>
            <li>
                <p>Liknoss Logo Change</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Bug Fixes:</strong>
        <ul>
            <li>
                <p>Fixed the issue when removing fare quotations from a trip.</p>
            </li>
            <li>
                <p>Resolved issues and made improvements to the use of ERP functionality.</p>
            </li>
            <li>
                <p>Fixed problems related to the functionality of the detailed availability shortcut ('F3').</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 111.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 111.0.0</h2>

<ul>
    <li>
        <strong>New Features:</strong>
        <ul>
            <li>
                <p>In Step 2, seat categories are now grouped based on the fare code<br>(for supported companies).</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Improvements:</strong>
        <ul>
            <li>
                <p>In Step 2, an indication has been added when the user selects an open return.</p>
            </li>
            <li>
                <p>Shortcut names in the shortcuts menu now have more understandable labels.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Bug Fixes:</strong>
        <ul>
            <li>
                <p>In Step 1, when the date changes in the slider, the search form calendar is now updated.</p>
            </li>
            <li>
                <p>Forms for open tickets and open return tickets have been added.</p>
            </li>
            <li>
                <p>In Step 1, the slider now has the same date format as the other calendars.</p>
            </li>
            <li>
                <p>In some cases, the fare quotation was not applied during ticket issuance.</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 110.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 110.0.0</h2>
        
<ul>
<li>
    <strong>Announcements:</strong>
    <ul>
        <li>
            <p>The announcements from partners are displayed from the new website of LIKNOSS.</p>
        </li>
    </ul>
</li>
</ul>


<!-- 109.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Version 109.0.0</h2>
<p style="text-align: center;">The new version of AgencyX brings changes that enhance the user experience, bug fixes, and the addition of useful features.</p>
<br>
<ul style="list-style-type: decimal-leading-zero;">
<li>
    <strong>Ticket Replacements:</strong>
    <ul>
        <li>
            <p>The ticket replacement feature has been added.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Shortcuts:</strong>
    <ul>
        <li>
            <p>You can configure shortcuts from the corresponding menu in the settings to perform actions more quickly in the application.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Changes in Element and Button Appearance:</strong>
    <ul>
        <li>
            <p>The appearance of various elements in the application has been adjusted to improve text readability.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Travel Duration & Credit Limit - Step 1:</strong>
    <ul>
        <li>
            <p>The duration of each trip and the credit limit for each company have been added.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Copy Passenger Name to Vehicle Details:</strong>
    <ul>
        <li>
            <p>Now, the first passenger's name is copied to the vehicle details.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Redesign of the Financial Data Screen:</strong>
    <ul>
        <li>
            <p>The Financial Data screen has been refreshed to be more user-friendly.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Mandatory Fields - Step 2:</strong>
    <ul>
        <li>
            <p>The headers in Step 2 have been adjusted to make the mandatory fields more visible.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Copy Reservation Code Button in Screen Voucher:</strong>
    <ul>
        <li>
            <p>A button for copying the reservation code to the Screen Voucher screen has been added.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Error Corrections:</strong>
    <ul>
        <li>
            <p>Issues related to shortcut settings have been addressed.</p>
        </li>
        <li>
            <p>In some cases, there was an error in the departure day during billing.</p>
        </li>
    </ul>
</li>
</ul>
`},
{ el: `
<!-- 119.5.0 -->
<h2 style="font-weight: bold; text-align: center;"> Έκδοση 119.5.0 </h2>
<ul>
  <li>
    <strong>Νέες Λειτουργίες:</strong>
    <ul>
      <li>
        <p>Προστέθηκαν επιπλέον πληροφορίες στην επιβεβαίωση κράτησης.</p>
      </li>
      <li>
        <p>Επαναφέρθηκε η δυνατότητα προβολής των σημειώσεων έκδοσης απευθείας μέσα στο περιβάλλον χρήστη.</p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Διορθώσεις Σφαλμάτων:</strong>
    <ul>
      <li>
        <p>Διορθώθηκαν ορισμένα σφάλματα στην ανάλυση τιμών.</p>
      </li>
      <li>
        <p>Επιλύθηκαν προβλήματα με τις κλήσεις συστήματος που πραγματοποιούνταν μετά την εμφάνιση της επιβεβαίωσης κράτησης.</p>
      </li>
      <li>
        <p>Διορθώθηκε ένα πρόβλημα όπου η ρύθμιση γλώσσας δεν εμφανιζόταν σωστά στις ρυθμίσεις.</p>
      </li>
      <li>
        <p>Επιλύθηκε ένα πρόβλημα όπου το σύστημα δεν λάμβανε σωστά υπόψη τις περιοχές τοποθεσίας κατά τις κλήσεις διαθεσιμότητας.</p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.4.0 -->
<h2 style="font-weight: bold; text-align: center;"> Έκδοση 119.4.0 </h2>
<ul>
  <li>
    <strong>Νέες Λειτουργίες:</strong>
    <ul>
      <li>
        <p>Προσθήκη επιβεβαίωσης πριν την έκδοση ως επιλογή στις ρυθμίσεις.</p>
      </li>
      <li>
        <p>Προστέθηκαν οι σημειώσεις κράτησης για κρατήσεις με την Viking.</p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Διορθώσεις Σφαλμάτων:</strong>
    <ul>
      <li>
        <p>Επιλύθηκαν προβλήματα με το ξεκλείδωμα θέσεων που επιλέχθηκαν στο πλάνο θέσεων.</p>
      </li>
      <li>
        <p>Διορθώθηκε ένα πρόβλημα με τις συντομεύσεις που ενεργοποιούσαν τις συντομεύσεις του Google Chrome.</p>
      </li>
      <li>
        <p>Επιλύθηκε ένα πρόβλημα όπου τα οχήματα ήταν διαθέσιμα ενώ δεν υπήρχε γκαράζ.</p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Βελτιώσεις:</strong>
    <ul>
      <li>
        <p>Βελτιώθηκε η εμφάνιση του πλάνου θέσεων.</p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.3.0 -->
<h2 style="font-weight: bold; text-align: center;"> Έκδοση 119.3.0 </h2>
<ul>
  <li>
    <strong>Διορθώσεις σφαλμάτων:</strong>
    <ul>
      <li>
        <p>Διορθώθηκε ένα πρόβλημα με την αντιγραφή δεδομένων οχήματος.</p>
      </li>
      <li>
        <p>Αντιμετωπίστηκαν ορισμένα προβλήματα κλήσεων δικτύου για συγκεκριμένους χρήστες.</p>
      </li>
      <li>
        <p>Βελτιώθηκαν τα μηνύματα σφάλματος σε ανακλήσεις κρατήσεων.</p>
      </li>
      <li>
        <p>Διορθώθηκε ένα σφάλμα σχετικά με την επικύρωση της κάρτας loyalty.</p>
      </li>
      <li>
        <p>Διορθώθηκε ένα λεκτικό που αφορούσε τα ακυρωτικά κρατήσεων.</p>
      </li>
      <li>
        <p>Επιλύθηκαν ορισμένα ζητήματα στη διαδικασία μεταφοράς κράτησης.</p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.2.0 -->
<h2 style="font-weight: bold; text-align: center;"> Έκδοση 119.2.0 </h2>
<ul>
  <li>
    <strong>Βελτιώσεις:</strong>
    <ul>
      <li>
        <p>
          Αλλαγές στα θερμικά εισιτήρια της ATC.
        </p>
      </li>
    </ul>
  </li>
</ul>

<!-- 119.1.0 -->
<h2 style="font-weight: bold; text-align: center;"> Έκδοση 119.1.0 </h2>

<ul>
 <li>
    <strong>Διορθώσεις σφαλμάτων:</strong>
    <ul>
      <li>
        <p>
          Διορθώθηκε ένα πρόβλημα όπου οι εκπτώσεις οχημάτων δεν εφαρμόζονταν σωστά.
        </p>
      </li>
      <li>
        <p>Διορθώθηκε ένα σφάλμα που εμπόδιζε τις ενημερώσεις δεδομένων ERP εντός της εφαρμογής.</p>
      </li>
      <li>
        <p>
          Επιλύθηκε ένα πρόβλημα που εμπόδιζε την εμφάνιση των ονομάτων των εταιρειών στο βήμα 1.
        </p>
      </li>
      <li>
        <p>
          Αντιμετωπίστηκαν διάφορα ζητήματα που βελτίωσαν συνολικά την εμπειρία χρήστη της εφαρμογής στο βήμα 1.
        </p>
      </li>
    </ul>
  </li>
</ul>
    
<!-- 119.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 119.0.0</h2>

<ul>
  <li>
    <strong>Βελτιώσεις:</strong>
    <ul>
      <li>
        <p>
          Η νέα οθόνη σύνδεσης προσφέρει μια πιο σύγχρονη και ευχάριστη εμπειρία χρήστη.
        </p>
      </li>
      <li>
        <p>
          Οι ενημερώσεις της desktop εφαρμογής έχουν βελτιωθεί σε αυτήν την έκδοση.
        </p>
      </li>
      <li>
        <p>
          Βελτιωμένη λειτουργία ERP.
        </p>
      </li>
      <li>
        <p>
          Υλοποιήθηκαν συγκεκριμένες αλλαγές για Ισπανούς επιβάτες.
        </p>
      </li>
      <li>
        <p>
          Βελτιωμένη λειτουργικότητα του slider ημερομηνιών στο Βήμα 1.
        </p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Διορθώσεις σφαλμάτων:</strong>
    <ul>
      <li>
        <p>
          Ένα ζήτημα σχετικό με την ανάκληση κρατήσεων με κωδικό εταιρείας έχει επιλυθεί.
        </p>
      </li>
      <li>
        <p>Διορθώθηκε ένα σφάλμα που εμπόδιζε την αναζήτηση για ταξίδια την τρέχουσα ημέρα.</p>
      </li>
      <li>
        <p>
          Επιλύθηκε ένα σφάλμα που εμπόδιζε την ακριβή αντιγραφή δεδομένων σε κρατήσεις με επιστροφή.
        </p>
      </li>
      <li>
        <p>
          Επιλύθηκε ένα ζήτημα που προκαλούσε σφάλματα κατά την ακύρωση εισιτηρίων και την επακόλουθη ενημέρωση του ERP.
        </p>
      </li>
      <li>
        <p>Διορθώθηκε το ζήτημα αντιγραφής δεδομένων επιβάτη με F4.</p>
      </li>
      <li>
        <p>Η επιλογή ταξιδιών στο βήμα 1 λειτουργεί πλέον με βάση το τύπο κράτησης.</p>
      </li>
      <li>
        <p>Το ζήτημα που εμπόδιζε την ορθή εμφάνιση τιμών και αριθμών θέσεων στο βήμα 2 έχει επιλυθεί.</p>
      </li>
      <li>
        <p>Το πρόβλημα που εμπόδιζε την εκτύπωση εισιτηρίων από την οθόνη ανάκλησης έχει επιλυθεί.</p>
      </li>
      <li>
        <p>Τα σφάλματα που σχετίζονται με τις τιμές κατοικίδιων ζώων στην οθόνη διαθεσιμότητας έχουν διορθωθεί.</p>
      </li>
      <li>
        <p>Το σφάλμα που εμπόδιζε το ξεκλείδωμα των επιλεγμένων θέσεων κατά την αποσύνδεση έχει διορθωθεί.</p>
      </li>
      <li>
        <p>Η αναζήτηση λιμανιών με ελληνικούς χαρακτήρες βελτιώθηκε.</p>
      </li>
      <li>
        <p>Το σφάλμα που σχετίζεται με την επικύρωση του email και του τηλεφώνου έχει διορθωθεί.</p>
      </li>
      <li>
        <p>Διορθώθηκαν τα προβλήματα κατά την εμφάνιση ταξιδιών στην οθόνη μεταφοράς ταξιδιού.</p>
      </li>
    </ul>
  </li>
</ul>


<!-- 118.5.1 -->
<h2 style="font-weight: bold; text-align: center;"> Έκδοση 118.5.1 </h2>
<ul>
    <li>
        <strong>Διόρθωση σφαλμάτων:</strong>
        <ul>
            <p>
                Επιλύθηκε ένα πρόβλημα που εμπόδιζε την έκδοση κρατήσεων με προσφορές Black Friday σε συγκεκριμένη εταιρεία.
            </p>
        </ul>
    </li>  
</ul>

<!-- 118.5.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 118.5.0</h2>


<ul>
  <li>
    <strong>Νέα Χαρακτηριστικά:</strong>
    <ul>
      <li>
        <p>
          Αυτή η ενημέρωση εισάγει νέα χαρακτηριστικά επικύρωσης κατά την
          πραγματοποίηση κράτησης.
        </p>
      </li>
      <li>
        <p>
          Έχει ενσωματωθεί το Sentry.io για βελτιωμένη παρακολούθηση σφαλμάτων
          και απόδοσης της εφαρμογής.
        </p>
      </li>
    </ul>
  </li>
  <li>
    <strong>Διορθώσεις Σφαλμάτων:</strong>
    <ul>
      <li>
        <p>
          Διορθώθηκε ένα σφάλμα που σχετιζόταν με την έκδοση optional κρατήσεων
          πολλαπλών διαδρομών.
        </p>
      </li>
      <li>
        <p>
          Διορθώθηκε ένα σφάλμα που αφορούσε την αντικατάσταση εισιτηρίων
          οχημάτων.
        </p>
      </li>
      <li>
        <p>
          Διορθώθηκε το σφάλμα που εμπόδιζε την αποστολή κατοικίδιων ζώων στα
          αιτήματα τιμολόγησης για μια συγκεκριμένη εταιρεία.
        </p>
      </li>
      <li>
        <p>
          Διορθώθηκε ένα σφάλμα που παρουσιαζόταν κατά τη μετατροπή των
          εισιτηρίων σε ανοιχτά.
        </p>
      </li>
      <li>
        <p>Διορθώθηκε ένα σφάλμα που επηρέαζε τα στατιστικά.</p>
      </li>
      <li>
        <p>
          Διορθώθηκε το σφάλμα που επηρέαζε την λίστα εταιρειών
          στο Βήμα 1.
        </p>
      </li>
      <li>
        <p>
          Διορθώθηκε το σφάλμα που εμπόδιζε την επιλογή ολόκληρης καμπίνας σε aller-retour κράτηση.
        </p>
      </li>
      <li>
        <p>
          Προστέθηκε η δυνατότητα να εμφανίζεται η προεπισκόπηση και εκτύπωση της κράτησης για τις εταιρείες που εκδίδουν etickets.
        </p>
      </li>
       <li>
        <p>
          Επιλύθηκαν προβλήματα που είχαν εμφανιστεί κατά την επιλογή θέσεων στη καρτέλα αναλυτικής διαθεσιμότητας.
        </p>
      </li>
      <li>
        <p>
          Διορθώθηκε το σφάλμα που εμπόδιζε την επιλογή και την προβολή της προεπισκόπησης μίας κράτησης στην οθόνη της ανάκλησης.
        </p>
      </li>
      <li>
        <p>
          Επιλύθηκαν προβλήματα που είχαν εμφανιστεί κατά την επιλογή extra services στη καρτέλα ανάκλησης.
        </p>
      </li>
      <li>
        <p>
          Διορθώθηκε το σφάλμα που διπλασίαζε την επιλεγμένη θέση στο Βήμα 2.
        </p>
      </li>
      <li>
        <p>
          Βελτιώθηκε η εμφάνιση και ο υπολογισμός της συνοπτικής διαθεσιμότητας.
        </p>
      </li>
    </ul>
  </li>
</ul>


<!-- 118.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 118.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p><strong>Ακυρωτικά ATC</strong></p>
                <p>Δείτε αναλυτικά στοιχεία για τα ακυρωτικά σε κρατήσεις ATC. Για να βρείτε την επιλογή ανοίξτε το δεξί μενού της εφαρμογής και μετά επιλέξτε «Ακυρωτικά ATC»</p>
            </li>   
            <li>
                <p><strong>Οι χρήστες της desktop εφαρμογής, μπορούν πλέον να τη χρησιμοποιούν χωρίς να έχουν δικαιώματα διαχειριστή.</strong></p>
            </li>
        </ul>
    </li>
</ul>

<!-- 117.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 117.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p><strong>Αντικατάσταση εισιτηρίων για κατοικίδια & έξτρα υπηρεσίες και μετατροπή σε open</strong></p>
                <p>Μπορείτε πλέον να κάνετε αντικατάσταση εισιτηρίων για κατοικίδια και έξτρα υπηρεσίες και να τα αλλάξετε σε κατάσταση open στις υποστηριζόμενες εταιρείες.</p>
            </li>   
            <li>
                <p><strong>Οι χρήστες θα αποσυνδέονται αυτόματα μετά από 1 ώρα αδράνειας.</strong></p>
            </li>
        </ul>
    </li>
</ul>

<!-- 116.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 116.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p><strong>Προσθήκη Εφαρμογής Στατιστικών</strong></p>
                <p>Τώρα μπορείτε να δείτε αναλυτικά στοιχεία για τις πωλήσεις σας μέσω της εφαρμογής των στατιστικών, χρησιμοποιώντας τα διαθέσιμα φίλτρα αναζήτησης. Για να πλοηγηθείτε στην εφαρμογή, ανοίξτε το δεξί μενού της εφαρμογής και μετά επιλέξτε «Στατιστικά»</p>
            </li>   
            <li>
                <p><strong>Προστέθηκε ο περιβαλλοντικός φόρος στα θερμικά εισιτήρια στις υποστηριζόμενες εταιρείες.</strong></p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Βελτιώσεις:</strong>
        <ul>
            <li>
                <p><strong>Προστέθηκε ο αριθμός θέσης κάθε εισιτηρίου στην ανάκληση κράτησης και στην εκτύπωση στοιχείων κράτησης.</strong></p>
            </li>
        </ul>
    </li>
</ul>

<!-- 115.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 115.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p><strong>Εξαργύρωση Πόντων με τις Κάρτες Seasmiles της Attica Group</strong></p>
                <p>Τώρα μπορείτε να εξαργυρώσετε τους πόντους που έχει μια κάρτα Seasmiles μέσω της εφαρμογής μας, με έναν εύκολο και φιλικό τρόπο.</p>
            </li>   
            <li>
                <p><strong>Προστέθηκε στα οχήματα ο τύπος καυσίμου στις υποστηριζόμενες εταιρείες.</strong></p>
            </li>  
        </ul>
    </li>
    <li>
        <strong>Βελτιώσεις:</strong>
        <ul>
            <li>
                <p>Στο Βήμα 2, βελτιώθηκε η εμπειρία του χρήστη κατά την καταχώρηση στοιχείων και μπορείτε πλέον να χρησιμοποιείτε τα βελάκια στο πληκτρολόγιο ή to Enter για να αλλάξετε πεδίο.</p>
            </li>
            <li>
                <p>Έγιναν βελτιώσεις στη λειτουργία της αντιγραφής με το F4.</p>
            </li>
        </ul>
    </li>
</ul>

<!-- 114.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 114.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p><strong>Εκπτωτικοί κωδικοί Vodafone CU & COSMOTE DEALS for YOU</strong></p>
                <p>Τώρα μπορείτε να κάνετε κρατήσεις, εξαργυρώνοντας κωδικούς Vodafone CU & COSMOTE DEALS for YOU μέσω της εφαρμογής, με έναν εύκολο και φιλικό τρόπο.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Βελτιώσεις:</strong>
        <ul>
            <li>
                <p>Έγιναν βελτιώσεις στην εμφανίση και λειτουργία του παραθύρου "Συμβουλή της Ημέρας"/ "Tip of the day".</p>
            </li>
            <li>
                <p>Προστέθηκε στην οθόνη της ανάκλησης και στο print draft το email της κράτησης.</p>
            </li>
            <li>
                <p>Προστέθηκε στα οχήματα το πεδίο "Αριθμός Εγγράφου" έτσι ώστε να μπορούν να εφαρμοστούν εκπτώσεις, στις εταιρείες που το υποστηρίζουν.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Διορθώσεις Σφαλμάτων:</strong>
        <ul>
            <li>
                <p>Έγιναν διορθώσεις στην εμφάνιση του παραθύρου της τιμολόγησης.</p>
            </li>
            <li>
                <p>Έγιναν βελτιώσεις στις αντικαταστάσεις εισιτηρίων, στα φίλτρα αναζήτησης στο Βήμα 1 και στην ταξινόμηση δρομολογίων.</p>
            </li>
            <li>
                <p>Διορθώθηκε το πρόβλημα κατά την αναζήτηση δρομολογίων από το slider ημερομηνιών, όπου δεν εμφανίζονταν όλα τα αποτελέσματα.</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 113.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 113.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p>Το δεξί κλίκ σε κάθε οθόνη εμφανίζει τις διαθέσιμες συντομεύσεις που μπορείτε να εκτελέσετε ανα πάσα στιγμή.</p>
            </li>
            <li>
                <p>Στα αποτελέσματα της αναζήτησης δρομολογίων, προστέθηκε το λογότυπο της εταιρείας και η φωτογραφία κάθε πλοίου ( όπου αυτά είναι διαθέσιμα ).</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Βελτιώσεις:</strong>
        <ul>
            <li>
                <p>Έγιναν βελτιώσεις στην εμφανίση και λειτουργία των extra services.</p>
            </li>
            <li>
                <p>Στην αναζήτηση δρομολογίων, τροποποιήθηκε ο επιλογέας της ώρας ώστε να μπορεί να γίνει αναζήτηση ανα 5 λεπτά της ώρας.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Διορθώσεις Σφαλμάτων:</strong>
        <ul>
            <li>
                <p>Διορθώθηκε το πρόβλημα όπου κατά την έκδοση optional κράτησης με fare quotation, δεν εφαρμοζόταν η έκπτωση.</p>
            </li>
            <li>
                <p>Διορθώθηκε το πρόβλημα που είχε εμφανιστεί κατά την έκδοση κράτησης με ασυνόδευτο όχημα.</p>
            </li>
            <li>
                <p>Έγιναν διορθώσεις στην εμφάνιση της οθόνης για τα οικονομικά δεδομένα.</p>
            </li>
            <li>
                <p>Διορθώθηκε το πρόβλημα όπου η εφαρμογή άλλαζε γλώσσα κάτα την μετάβαση από μία οθόνη σε μία άλλη.</p>
            </li>
            <li>
                <p>Διορθώθηκε το πρόβλημα στις aller-retour κρατήσεις, όπου κατά την μετάβαση από το πρώτο ταξίδι στο δεύτερο, εμφανιζόταν λάθος το συνολικό ποσό του δεύτερου ταξιδιού.</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 112.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 112.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p>Κατοικίδια: Υποστηρίζεται η έκδοση εισιτηρίων με κατοικίδια. Μπορείτε να προσθέσετε κατοικίδια σε μία κράτηση από την ενότητα των extra services στο Βήμα 2.</p>
            </li>
            <li>
                <p>Συμβουλές: Κατά την εκκίνηση θα σας εμφανίζονται συμβουλές σχετικά με τη χρήση της εφαρμογής.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Βελτιώσεις:</strong>
        <ul>
            <li>
                <p>Τα μηνύματα επιτυχίας, σφάλματος, προειδοποίησης μπορούν να παραμένουν στην οθόνη μέχρι να τα κλείσετε. Μπορείτε να ενεργοποιήσετε την αντίστοιχη επιλογή από την οθόνη των ρυθμίσεων.</p>
            </li>
            <li>
                <p>Έγιναν βελτιώσεις στην εμφανίση της οθόνης διαθεσιμότητας. Επίσης προστέθηκαν επιπλέον πληροφορίες για τις θέσεις στις εταιρείες που το υποστηρίζουν.</p>
            </li>
            <li>
                <p>Αλλαγή Λογότυπου Liknoss</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Διορθώσεις Σφαλμάτων:</strong>
        <ul>
            <li>
                <p>Διορθώθηκε το πρόβλημα που υπήρχε στην αφαίρεση του fare quotation από ένα ταξίδι.</p>
            </li>
            <li>
                <p>Έγιναν διορθώσεις και βελτιώσεις στη χρήση της ERP λειτουργίας.</p>
            </li>
            <li>
                <p>Διορθώθηκαν προβλήματα που αφορούσαν την λειτουργία της συντομέυσης της αναλυτικής διαθεσιμότητας ('F3').</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 111.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 111.0.0</h2>

<ul>
    <li>
        <strong>Καινούργιες Λειτουργίες:</strong>
        <ul>
            <li>
                <p>Στο Βήμα 2 οι κατηγορίες θέσεων ομαδοποιούνται με βάση το farecode<br>( στις εταιρείες που το υποστηρίζουν ).</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Βελτιώσεις:</strong>
        <ul>
            <li>
                <p>Στο Βήμα 2 προστέθηκε ένδειξη όταν ο χρήστης έχει επιλέξει ανοικτή επιστροφή.</p>
            </li>
            <li>
                <p>Οι συντομεύσεις στο μενού συντομεύσεων έχουν πιο κατανοητές ονομασίες.</p>
            </li>
        </ul>
    </li>
    <li>
        <strong>Διορθώσεις Σφαλμάτων:</strong>
        <ul>
            <li>
                <p>Στο Βήμα 1 όταν αλλάζει η ημερομηνία στο slider, ενημερώνεται το ημερολόγιο της φόρμας αναζήτησης.</p>
            </li>
            <li>
                <p>Προστέθηκαν οι φόρμες για open εισιτήρια και εισιτήρια ανοικτής επιστροφής.</p>
            </li>
            <li>
                <p>Στο Βήμα 1 το slider έχει πλέον το ίδιο format ημερομηνίας με τα υπόλοιπα ημερολόγια.</p>
            </li>
            <li>
                <p>Σε ορισμένες περιπτώσεις δεν εφαρμοζόταν το fare quotation κατά την έκδοση εισιτηρίων.</p>
            </li>
        </ul>
    </li>
</ul>


<!-- 110.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 110.0.0</h2>
        
<ul>
<li>
    <strong>Ανακοινώσεις Συνεργατών:</strong>
    <ul>
        <li>
            <p>Οι ανακοινώσεις συνεργατών εμφανίζονται από την καινούργια ιστοσελίδα της LIKNOSS.</p>
        </li>
    </ul>
</li>
</ul>


<!-- 109.0.0 -->
<h2 style="font-weight: bold; text-align: center;">Έκδοση 109.0.0</h2>
        <p style="text-align: center;">Η νέα έκδοση του AgencyX φέρνει αλλαγές που βελτιώνουν την εμπειρία του χρήστη, διορθώσεις σφαλμάτων και προσθήκες χρήσιμων χαρακτηριστικών.</p>
<br>
<ul style="list-style-type: decimal-leading-zero;">
<li>
    <strong>Αντικαταστάσεις εισιτηρίων:</strong>
    <ul>
        <li>
            <p>Προστέθηκε η λειτουργία αντικατάστασης εισιτηρίων.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Συντομεύσεις:</strong>
    <ul>
        <li>
            <p>Μπορείτε να ρυθμίσετε τις συντομεύσεις από το αντίστοιχο μενού στις ρυθμίσεις προκειμένου να εκτελείτε πιο γρήγορα ενέργειες στην εφαρμογή.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Αλλαγές στην εμφάνιση στοιχείων και κουμπιών:</strong>
    <ul>
        <li>
            <p>Προσαρμόστηκε η εμφάνιση διαφόρων στοιχείων στην εφαρμογή προκειμένου να βελτιωθεί η
                αναγνωσιμότητα του κειμένου.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Διάρκεια Ταξιδιού & Credit Limit - Βήμα 1:</strong>
    <ul>
        <li>
            <p>Προστέθηκε η διάρκεια του κάθε ταξιδιού και το credit limit της εκάστοτε εταιρείας.
            </p>
        </li>
    </ul>
</li>
<li>
    <strong>Αντιγραφή ονοματεπώνυμου στα στοιχεία του οχήματος:</strong>
    <ul>
        <li>
            <p>Πλέον αντιγράφεται το ονοματεπώνυμο του πρώτου επιβάτη στα στοιχεία του οχήματος.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Ανασχεδιασμός της οθόνης Financial Data:</strong>
    <ul>
        <li>
            <p>Ανανεώθηκε η οθόνη για τα Financial Data προκειμένου να είναι πιο φιλική προς τον χρήστη.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Υποχρεωτικά πεδία - Βήμα 2:</strong>
    <ul>
        <li>
            <p>Προσαρμόστηκαν οι επικεφαλίδες στο Βήμα 2, ώστε τα υποχρεωτικά πεδία να είναι πιο εμφανή.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Κουμπί Αντιγραφής Κωδικού Κράτησης Screen Voucher:</strong>
    <ul>
        <li>
            <p>Προστέθηκε ένα κουμπί για την αντιγραφή του κωδικού κράτησης στην οθόνη του Screen Voucher.</p>
        </li>
    </ul>
</li>
<li>
    <strong>Διορθώσεις Σφαλμάτων:</strong>
    <ul>
        <li>
            <p>Αντιμετωπίστηκαν προβλήματα που σχετίζονταν με τις ρυθμίσεις των συντομεύσεων.</p>
        </li>
        <li>
            <p>Σε ορισμένες περιπτώσεις υπήρχε σφάλμα στην ημέρα αναχώρησης κατά την τιμολόγηση.</p>
        </li>
    </ul>
</li>
</ul>`}];


export default newVersionData;