import moment from 'moment'
import axios from '../../client/axios'
import { BASE_URL_API_GUI } from '../../settings'
export const TripRequestReferenceBodyDTO = ({
  departureDateTime,
  arrivalDateTime,
  departureTime,
  arrivalTime,
  arrivalDate,
  specificTime,
  origin,
  destination,
  fetchIntermediaryStops,
  vessel,
  fetchExtraServices,
  company,
  availabilityInformation,
  fetch
}) => {
  // departureDate = moment(departureDate).isValid() ? moment(departureDate).format('YYYY-MM-DD') : ''
  // departureTime = moment(departureTime).isValid() ? moment(departureTime).format('HH:mm') : undefined
  // arrivalTime = moment(arrivalDate).isValid() && arrivalTime !== undefined ? moment(arrivalDate).format('HH:mm') : undefined
  // arrivalDate = moment(arrivalDate).isValid() && arrivalTime !== undefined ? moment(arrivalDate).format('YYYY-MM-DD') : undefined
  // const departureTimeValue = () => fetch ? departureTime : specificTime !== 'Invalid date' ? specificTime : undefined
  return {
    departureDateTime,
    arrivalDateTime,
    origin,
    destination,
    vessel
    // sorting: 'BY_DEPARTURE_TIME',
    // fetchIntermediaryStops,
    // fetchExtraServices,
    // vesselIdOrCode,
    // availabilityInformation: availabilityInformation || undefined
    // fetchVehicleAccommodations: true,
    // fetchInfantAccommodations: true,
    // quoteRequest: {
    //   passengers: 1,
    //   vehicles: 0,
    //   pets: 0
    // }
  }
}
export const TripRequestBodyDTO = ({
  departureDate,
  departureTime,
  arrivalTime,
  arrivalDate,
  specificTime,
  originIdOrCode,
  destinationIdOrCode,
  fetchIntermediaryStops,
  vesselIdOrCode,
  fetchExtraServices,
  company,
  availabilityInformation,
  projectId,
  fetch
}) => {
  departureDate = moment(departureDate).isValid() ? moment(departureDate).format('YYYY-MM-DD') : ''
  departureTime = moment(departureTime).isValid() ? moment(departureTime).format('HH:mm') : undefined
  arrivalTime = moment(arrivalDate).isValid() && arrivalTime !== undefined ? moment(arrivalDate).format('HH:mm') : undefined
  arrivalDate = moment(arrivalDate).isValid() && arrivalTime !== undefined ? moment(arrivalDate).format('YYYY-MM-DD') : undefined
  const departureTimeValue = () => fetch ? departureTime : specificTime !== 'Invalid date' ? specificTime : undefined
  return {
    projectId,
    departureDate,
    arrivalTime,
    arrivalDate,
    company,
    departureTime: departureTimeValue(),
    originIdOrCode,
    destinationIdOrCode,
    sorting: 'BY_DEPARTURE_TIME',
    fetchIntermediaryStops,
    fetchExtraServices,
    vesselIdOrCode,
    availabilityInformation: availabilityInformation || undefined
    // fetchVehicleAccommodations: true,
    // fetchInfantAccommodations: true,
    // quoteRequest: {
    //   passengers: 1,
    //   vehicles: 0,
    //   pets: 0
    // }
  }
}

export const TripPerDayRequestBodyDTO = ({
  departureDate,
  departureTime,
  originIdOrCode,
  specificTime,
  destinationIdOrCode,
  sorting,
  daysToSearch,
  company,
  fetch
}) => {
  departureDate = moment(departureDate).isValid() ? moment(departureDate).format('YYYY-MM-DD') : undefined
  departureTime = moment(departureTime).isValid() ? moment(departureTime).format('HH:mm') : undefined
  const departureTimeValue = () => fetch ? departureTime : specificTime !== 'Invalid date' ? specificTime : undefined

  return {
    departureDate,
    departureTime: departureTimeValue(),
    originIdOrCode,
    destinationIdOrCode,
    company,
    sorting: sorting || 'BY_DEPARTURE_TIME',
    daysToSearch: daysToSearch
    // forTrucks,
    // forGroups,
    // forPackages,
    // includeConjunctions,
    // maxConjunctions,
    // hasDangerousGoods,
  }
}

/**
 * This method provides with trips availabilities
 * The criteria entity is called “Time table” request.
 *
 * @param { Object } params
 * @param { Object } params.body - The request body
 * @param { String } params.token - The access token string
 *
 */
export const apiTripsAvailability = ({ body, token }) =>{
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/availability',
    headers: {...token,  "language-code": $nuxt.$i18n.locale},
    data: {
      ...body
    }
  }).then(res => res.data)
}

/**
 * This method provides with trips for specific criteria.
 * The criteria entity is called “Time table” request.
 *
 * @param { Object } params
 * @param { Object } params.body - The request body
 * @param { String } params.token - The access token string
 *
 */
export const apiTrips = ({ body, token }) =>{
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/trips',
    headers: token,
    data: {
      ...body
    }
  }).then(res => res.data)
}

export const apiProjectListOfTrips = ({ project, body, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/list-of-trips/${project}`,
    headers: {...token,  "language-code": $nuxt.$i18n.locale},
    data: Array.isArray(body) ? body : [body],
  }).then(res => {
    return res.data
  }
  )
}

/**
 * This method provides with list of trips for specific criteria.
 * The criteria entity is called “Time table” request.
 *
 * @param { Object } params
 * @param { Object } params.body - The request body
 * @param { String } params.token - The access token string
 *
 */
 export const apiListOfTrips = ({ body, token }) =>{
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/list-of-trips',
    headers: {...token,  "language-code": $nuxt.$i18n.locale},
    data: Array.isArray(body) ? body : [body],
  }).then(res => res.data)
}

export const apiPricingBookingReferenceData = ({ body, token }) =>{
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/pricing-booking-reference-data',
    headers: token,
    data: body
  }).then(res => {
    // const merger = (res) => {
    //   let locations = {}
    //   let companies = {}
    //   let trips = []
    //   res.map(r => {
    //     locations = merge(locations, r.locations)
    //     companies = merge(companies, r.companies)
    //     trips = [...trips, ...r.trips]
    //   })
    //   return {
    //     locations,
    //     companies,
    //     trips
    //   }
    // }
    return res.data.trips
  })
}

/**
 * This method provides with timetable data grouped by day.
 * Field “daysToSearch” stores the number of days to search prior and after “departureDate”.
 * The entity used, for posting data, is “Time table”, in JSON format.
 * This method does not fetch availability data and route analysis.
 *
 * @param { Object } params
 * @param { Object } body - Request body
 * @param { String } token - Access token string
 *
 */
export const apiTripsPerDay = ({ body, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/trips-per-day',
    headers: token,
    data: body
  }).then(res => res.data)

/**
 * This method return the seatmap of ATS type of seat.
 *
 * @param { Object } params
 * @param { Object } body - Request body
 * @param { String } token - Access token string
 *
 */
export const seatMapForATS = ({ body, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/accommodation-plan',
    headers: token,
    data: body
  }).then(res => res.data)

/**
 * This method return the seatmap of CABINS.
 *
 * @param { Object } params
 * @param { Object } body - Request body
 * @param { String } token - Access token string
 *
 */
export const seatMapForCabins = ({ body, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/get-cabins-details',
    headers: { Authorization: `Bearer ${token}` },
    data: body
  }).then(res => res.data)

/**
 * This method return the available countries.
 *
 * @param { Object } params
 * @param { String } token - Access token string
 *
 */
export const countries = ({ body, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/countries',
    headers: {...token,  "language-code": $nuxt.$i18n.locale},
  }).then(res => res.data)

  export const vesselList = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/vessels',
    headers: token,
    params
  }).then(res => res.data)

/**
 * This method used to validate the man code provided.
 *
 * @param { Object } params
 * @param { String } token - Access token string
 *
 */
export const validateManCode = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/validate-loyalty-and-residency-data',
    headers: token,
    data
  }).then(res => res.data)

/**
 * This method is used to validate and fetch data for the provided loyalty card.
 *
 * @param { Object } params
 * @param { String } token - Access token string
 *
 */
export const validateLoyaltyData = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/validate-loyalty-data',
    headers: token,
    data
  }).then(res => res.data)

/**
 * This method is used to calculate loyalty miles for the provided loyalty card.
 *
 * @param { Object } params
 * @param { String } token - Access token string
 *
 */
export const calculateLoyaltyMiles = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/calculate-loyalty-miles',
    headers: token,
    data
  }).then(res => res.data)

/**
 * This method return the available payment methods.
 *
 * @param { Object } params
 * @param { String } token - Access token string
 *
 */
export const availablePaymentMethods = ({ body, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/payment-method',
    headers: token,
    data: body
  }).then(res => res.data)

/**
 * This method lock the seat/cabin that we send to api.
 *
 * @param { Object } params
 * @param { String } token - Access token string
 *
 */
export const lockAccomodations = ({ body, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/lock-accommodation-space',
    headers: token,
    data: body
  }).then(res => res.data)

/**
 * This method unlock the seat/cabin that we send to api.
 *
 * @param { Object } params
 * @param { String } token - Access token string
 *
 */
export const unlockAccomodations = ({ body, token }) =>{
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/unlock-accommodations',
    headers: token,
    data: body
  }).then(res => res.data)
}
