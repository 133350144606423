import axios from '../../client/axios'
import { BASE_URL_API_B2B } from '../../settings'

export const apiCheckinGetVessels = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_B2B,
    url: process.env.API_BASE_PATH_GUI + '/vessels',
    headers: { Authorization: `Bearer ${token}` },
    params
  }).then(res => res.data)
