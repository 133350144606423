import axios from '../../client/axios'
import { BASE_URL_API_GUI } from '../../settings'

/**
 * Get the access token
 *
 * @param { String } username
 * @param { String } password
 * @param { String } validation_code
 *
 * @returns { Promise }
 *
 * Example response:
 * {
    "access_token": "BbclXl_MWcSELIR_LY0X9P9yE_ogde5rqmu_LecWFjsXsMazmRvYtcGVlDw3KAmBU0oWu6wEQWX4N3FefJWG6CyELv2__nBR6B0efd7Nc-AdYKgUyVMK72YmifZPbGCghjHnL9FuhV_Vg7lso2xoHX9L1nkuNSw2cwkmSBb0FQ-JzVTBRQAJ4ow5SW6BDG4pvM5rlVbtqqYRiwV3QaUICsv0SwLhWydsjMdfELfc_ChT_3MYjV5B6KX5OA8sNaR3cmxKOms89AeD5A_Kx0039mcIREcgvb9fF6WKBuLuBBjWzeGIxNaqM4Q1JraLLbwi",
    "token_type": "bearer",
    "expires_in": 14399,
    "userName": "Sofia_1",
    ".issued": "Fri, 13 Mar 2020 10:15:32 GMT",
    ".expires": "Fri, 13 Mar 2020 14:15:32 GMT"
  }
 */
export const apiLogin = (username, password, agencyCode, agencySignature, getMinorVersion, getMajorVersion, getPatchVersion,validation_code,validation_code_expiry_in_minutes) =>

axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/token',
    headers: {
      'Content-Type': 'application/json',
      'agency-code': agencyCode || '1300',
      'agency-user-name': username,
      'agency-password': password,
      "language-code": $nuxt.$i18n.locale,
      "agency-x-major-version": getMajorVersion,
      "agency-x-minor-version": getMinorVersion,
      "agency-x-version": getMajorVersion + '.' + getMinorVersion + '.' + getPatchVersion,
      "agency-x-env":  process.env.ENVIRONMENT,
      "validation-code": validation_code || '',
      "validation-code-expiry-in-minutes": validation_code_expiry_in_minutes || ''
    }
  }).then(res => res.data)


export const changePassword = ({ data, token }) => {
  return axios({
    method: 'PUT',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/change-agency-user-password',
    headers: {
      ...token,
      'new-password': data.password,
    },
    // data
  }).then(res => res.data)
}
