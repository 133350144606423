import _get from 'lodash/get'
import axios from '../../client/axios'
import _groupBy from 'lodash/groupBy'
import pathOr from 'ramda/src/pathOr'
import _difference from 'lodash/difference'
import { BASE_URL_API_GUI } from '../../settings'
import { discountIdOrCode, AdditionalServicesDTO } from './pricing'

const attrs = selectedPassengerOrVehicle => {
  let res = Object.entries(selectedPassengerOrVehicle || {})
  .filter(([key, value]) => ((key !== "booking_attribute_discountidorcode" && key !== 'booking_attribute_loyaltycard') && (key !== "pricing_attribute_discountidorcode" && key !== 'pricing_attribute_loyaltycard')) )
  .filter(([key, value]) => (key.includes('booking_attribute') && !key.includes('booking_attribute_phone') || key.includes('pricing_attribute') && !key.includes('pricing_attribute_phone')    ) && value && typeof value.selectedValue !== 'undefined')
  .reduce((obj, [key, value]) => {
    return {
      ...obj,
      [value.fieldName]: value.selectedValue,
    };
  }, {})
  if (selectedPassengerOrVehicle.ticketLetter || selectedPassengerOrVehicle.ticketNumber) {
    res.ticket = {
      letter: selectedPassengerOrVehicle.ticketLetter,
      number: selectedPassengerOrVehicle.ticketNumber,
    }
  }
  return res
}

const typeValue = (selPass) => {
  return selPass?.pricing_attribute_type?.selectedValue
}

const sexTypeValue = (selPass) => {
  return selPass?.pricing_attribute_sextype?.selectedValue
}

const discountDocument = accommodation => {
  return _get(accommodation, 'pricing_attribute_discountdocument.selectedValue', undefined)
}

const loyaltyCardAttr = accommodation => {
  return _get(accommodation, 'pricing_attribute_loyaltycard.selectedValue', undefined)
}

const lengthValue = (selVeh) => {
  return selVeh?.pricing_attribute_length?.selectedValue
}

const accommodationRequestAnalysisesVehicles = vehicles => {
  return vehicles.map((selectedVehicle, index) => ({
    index: index + 1,
    discountDocument: discountDocument(selectedVehicle),
    discountIdOrCode: discountIdOrCode(selectedVehicle),
    loyaltyCard: loyaltyCardAttr(selectedVehicle),
    vehicleData: {...attrs(selectedVehicle), discountDocument: undefined, length: lengthValue(selectedVehicle), priorityNumber: selectedVehicle.priorityNumber}
  }))
}

export const accommodationRequestAnalysises = passengers => {
  return passengers.map((selectedPassenger, index) => {
    let documenttypeAttribute = selectedPassenger.pricing_attribute_documenttype ? selectedPassenger.pricing_attribute_documenttype.selectedValue : '';
    return {
      index: index + 1,
      discountIdOrCode: discountIdOrCode(selectedPassenger),
      loyaltyCard: loyaltyCardAttr(selectedPassenger),
      passengerData: {
        ...attrs(selectedPassenger), 
        documentType: documenttypeAttribute ,
        sexType: sexTypeValue(selectedPassenger), 
        type: typeValue(selectedPassenger), 
        accommodationNumber: pathOr(false, ['price', 'accommodationNumber'], selectedPassenger) 
          ? selectedPassenger.price.accommodationNumber 
          : selectedPassenger.accommodationNumber
            ? selectedPassenger.accommodationNumber
            : selectedPassenger?.selectedSeat?.accommodationNumber
      }
    }
  })
}

const accommodationType = (selectedPassenger) => {
  if(selectedPassenger.pricing_attribute_sextype.selectedValue) return `${selectedPassenger.pricing_attribute_sextype.selectedValue}_BERTH`
}

const accommodationRequestAnalysisesGroups = passengers => {
  return passengers.map((selectedPassenger, index) => ({
    index: index + 1,
    discountIdOrCode: discountIdOrCode(selectedPassenger),
    loyaltyCard: loyaltyCardAttr(selectedPassenger),
    passengerData: {
      ...attrs(selectedPassenger), 
      documentType: selectedPassenger.pricing_attribute_documenttype ? selectedPassenger.pricing_attribute_documenttype.selectedValue : '',  
      sexType: selectedPassenger.sex, 
      type: selectedPassenger.type, 
      accommodationNumber: pathOr(false, ['selectedPassenger','price', 'accommodationNumber'], selectedPassenger) 
        ? selectedPassenger.price.accommodationNumber 
        : selectedPassenger.accommodationNumber
          ? selectedPassenger.accommodationNumber
          : selectedPassenger?.selectedSeat?.accommodationNumber
    }
  }))
}

const accommodationTypeGroups = (selectedPassenger) => {
  if(selectedPassenger.sex) return `${selectedPassenger.sex}_BERTH`
}

const PassengerAccomodationGroupRequestDTO = (pass, passGroup) => {
  const passIncludedInGroup = (p) => passGroup && passGroup.length && passGroup.includes(p)
  const selectedPassenger = Array.isArray(pass) ? pass[0] : pass
  const passengers = Array.isArray(pass) ? pass : [pass]
  return {
    idOrCode: selectedPassenger.accommodation.idOrCode,
    accommodationRequestType: passIncludedInGroup(selectedPassenger) ? 'COMPLETE' : accommodationTypeGroups(selectedPassenger),
    bedType: 'NO_PREFERENCE',
    quantity: 1,
    accommodationRequestAnalysises: accommodationRequestAnalysisesGroups(passengers)
  }
}

const PassengerAccomodationRequestDTO = (pass, passGroup) => {
  const passIncludedInGroup = (p) => passGroup && passGroup.length && passGroup.includes(p)
  const selectedPassenger = Array.isArray(pass) ? pass[0] : pass
  const passengers = Array.isArray(pass) ? pass : [pass]
  return {
    idOrCode: selectedPassenger.accommodationType.accommodation.idOrCode,
    accommodationRequestType: passIncludedInGroup(selectedPassenger) ? 'COMPLETE' : accommodationType(selectedPassenger),
    bedType: 'NO_PREFERENCE',
    ...(selectedPassenger?.accommodationType?.fareCode && {fareCode: selectedPassenger?.accommodationType?.fareCode}),
    quantity: 1,
    accommodationRequestAnalysises: accommodationRequestAnalysises(passengers)
  }
}

const VehicleAccomodationRequestDTO = (veh, withOptionsPerTicket) => {
  const selectedVehicle = Array.isArray(veh) ? veh[0] : veh
  const vehicles = Array.isArray(veh) ? veh : [veh]
  return {
    idOrCode: selectedVehicle?.accommodationType?.accommodation?.idOrCode, // 'ECO',
    accommodationRequestType: 'VEHICLE',
        quantity: 1,
    accommodationRequestAnalysises: accommodationRequestAnalysisesVehicles(vehicles)
  }
}

const PassengerAccomodationRequestDTOComplete = (selectedPassengers) => {
  return {
    idOrCode: selectedPassengers[0]?.accommodationType?.accommodation?.idOrCode, 
    accommodationRequestType: 'COMPLETE',
    bedType: 'NO_PREFERENCE',
    ...(selectedPassengers[0]?.accommodationType?.fareCode && {fareCode: selectedPassengers[0]?.accommodationType?.fareCode}),
    quantity: 1,
    accommodationRequestAnalysises: selectedPassengers.map((p) => {
      return {
        index: 1,
        discountIdOrCode: discountIdOrCode(p),
        loyaltyCard: loyaltyCardAttr(p),
        passengerData: {...attrs(p), sexType: sexTypeValue(p), type: typeValue(p), accommodationNumber: p?.selectedSeat?.accommodationNumber ? p?.selectedSeat?.accommodationNumber : p?.accommodationNumber}
      }
    })
  }
}

const TripOptionsDTO = (trip, companies, isGroup, crsReservationId, alternateAgencyCode, hasExtraServices) => {
 
  const copiedTrip = JSON.parse(JSON.stringify(trip));
  const passGroup = Object.values(_groupBy(copiedTrip.selectedPassengers, 'passIndex')).map(p => p.filter(a => p.every(i => i.passIndex !== undefined)))


  const completeCabin = passGroup.map(g => {
    const tripCompany = copiedTrip.vessel.company.abbreviation
    const accIdOrCode = g[0]?.accommodationType.accommodation?.idOrCode
    const cabinCapacity = companies?.[tripCompany].accommodations.passengers[accIdOrCode]?.capacity
    return g?.length <= cabinCapacity
  })

  const group = completeCabin.map((a, i) => a && passGroup[i]).flat().filter(el => Object.keys(el).length)

  const notCompletePass = _difference(Object.values(copiedTrip.selectedPassengers || {}), group)

  if (copiedTrip?.vessel?.company?.abbreviation === "MNA") {
    Object.values(copiedTrip.selectedPassengers).map(p => p.accommodationNumber = "")
  }
  
  let accommodationRequests = []
  accommodationRequests = Object.values(_groupBy(group, 'passIndex') || {})
    .map((pass) => PassengerAccomodationRequestDTOComplete(pass))
    .concat(copiedTrip.selectedVehicles && Object.values(copiedTrip.selectedVehicles).map(VehicleAccomodationRequestDTO))

  let groupPassengers = []
  let groupVehicles = []

    if(isGroup){
      accommodationRequests = [...accommodationRequests, ...notCompletePass.map(ncp => PassengerAccomodationGroupRequestDTO(ncp))]
      groupPassengers = JSON.parse(JSON.stringify(copiedTrip.groupPassengers))
      groupPassengers.forEach(p => delete p.index)
      groupVehicles = JSON.parse(JSON.stringify(copiedTrip.groupVehicles))
      groupVehicles.forEach(v => delete v.index)
    }
    
    if(notCompletePass.length && !isGroup){
      accommodationRequests = [...accommodationRequests, ...notCompletePass.map(ncp => PassengerAccomodationRequestDTO(ncp)) ]
    }

    return {
      companyReservationCode: crsReservationId?.toString() || undefined,
      alternateAgencyCode: alternateAgencyCode || undefined,
      departureDateTime: copiedTrip.departureDateTime,
      origin: {
        idOrCode: copiedTrip.origin.idOrCode
      },
      destination: {
        idOrCode: copiedTrip.destination.idOrCode
      },
      remarks: copiedTrip.remarks,
      vessel: copiedTrip.vessel,
      accommodationRequests: accommodationRequests.filter(ac => ac && Object.keys(ac).length),
      additionalServices: AdditionalServicesDTO(copiedTrip.additionalServices || [], hasExtraServices),
      groupPassengers: groupPassengers,
      groupVehicles: groupVehicles,
    }
}

export const ValidateResidentDataDTO = ({
  trips,
}) => {
  return {
    trips: trips.map(t => TripOptionsDTO(t, companies))
  }
}

export const BookingRequestBodyDTO = ({
  trips,
  leaderPhone,
  leaderMail,
  emergencyPhone,
  companies,
  printOnlyReceipt,
  openReturn,
  remarks,
  crsReservationId,
  insurances,
  alternateAgencyCode,
  projectCode,
  voucherCode,
  farePerCompanies,
  hasExtraServices,
  userSelectedEticket
}) => {
  const selectedPassengerData = Object.values(trips[0].selectedPassengers)[0] || {};

  return {
    pta: printOnlyReceipt,
    ...(userSelectedEticket && { userSelectedEticket: userSelectedEticket }),
    insurances,
    voucherCode,
    projectCode,
    openReturn,
    leader: {
      phone: leaderPhone,
      mobile: leaderPhone,
      email: leaderMail,
      firstName: selectedPassengerData.booking_attribute_name?.selectedValue ?? '',
      lastName: selectedPassengerData.booking_attribute_surname?.selectedValue ?? '',
      emergencyPhone,
    },
    trips: trips.map((t) => {
      return TripOptionsDTO({ ...t, remarks }, companies, false, crsReservationId, alternateAgencyCode, hasExtraServices);
    }),
    ...(farePerCompanies && {farePerCompanies: farePerCompanies})
  };
};


export const UpdateGroupBodyDTO = ({
  trips,
  fareIdOrCode,
  leaderPhone,
  company_abbreviation,
  cmp_reservation_code,
  companies,
}) => {
  return {
    fareIdOrCode,
    // leader: {
    //   phone: leaderPhone,
    //   mobile: leaderPhone,
    //   emergencyPhone,
    // },
    companies,
    company_abbreviation,
    cmp_reservation_code,
    // trips: trips
    trips: trips.map(t => TripOptionsDTO(t, companies, true))
  }
}

export const ModifyBookingDTO = ({ trips, crsReservationCode, leader,  dryRun, optionDateTime, paymentMethods }) => {
  return {
    crsReservationCode,
    optionDateTime,
    trips,
    leader
  }
}

export const modifyBooking = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/modify',
    headers: token,
    data
  }).then(res => res.data)

export const changeOptionDateTime = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/change-option-datetime',
    headers: token,
    data
  }).then(res => res.data)

/**
 * This method books a specific quote. The criteria entity is called “Booking request”. The structure of the entity can be found on the table, below.
 * documentation https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B/doFinalizeBooking
 *
 * @param { Object } data
 * @property { Object } data.data - The request body
 * @property { String } data.token - The access token for authorization
 */
export const apiBooking = ({ data, token, otherAccount }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/multi-booking',
    headers: token,
    data
  }).then(res => res.data)
}

/**
 * This method books a specific quote that contains open tickets. The criteria entity is called “Booking request”. The structure of the entity can be found on the table, below.
 * documentation https://hermes.liknoss.com/cws/docs/gui/swagger.jsp#/GUI/doReplaceOpenTickets
 *
 * @param { Object } data
 * @property { Object } data.data - The request body
 * @property { String } data.token - The access token for authorization
 */
export const apiReplaceOpenTickets = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/replace-open-tickets',
    headers: token,
    data
  }).then(res => res.data)
}

/**
 * This method books a specific quote that contains open tickets. The criteria entity is called “Booking request”. The structure of the entity can be found on the table, below.
 * documentation https://hermes.liknoss.com/cws/docs/gui/swagger.jsp#/GUI/doReplaceOpenTicketsByOptionalBooking
 *
 * @param { Object } data
 * @property { Object } data.data - The request body
 * @property { String } data.token - The access token for authorization
 */
export const apiReplaceOpenTicketsByOptionalBooking = ({ data, params, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/replace-open-tickets/${params.crsReservationId}`,
    headers: token,
    data
  }).then(res => res.data)
}

/**
 * 
 * This method fetches price difference of open tickets to be replaced.
 *
 * @param { Object } data
 * @property { Object } data.data - The request body
 * @property { String } data.token - The access token for authorization
 */
export const apiPriceDifferenceForReplaceOpenTickets = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/price-diff-for-open',
    headers: token,
    data
  }).then(res => res.data)
}

/**
 * This method fetches a previously booked (optional o).
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B/doRetrieveBooking_1
 *
 * @param { Object } data
 * @property { Object } data.params - {
 *  crsReservationIds: string,
 *  crsReservationId: string,
 *  ticketNumber: string,
 *  fromCode: string,
 *  toCode: string,
 *  departureDate: string,
 *  arrivalDate: string,
 *  surname: string,
 *  plateNumber
 * }
 * @property { String } data.token
 * @returns {Promise<Array>}
 */
export const apiRetrieveBookings = ({ params, token }) => {
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/retrieve-bookings',
    headers: token,
    params: {
      crsReservationIds: params.crsReservationIds
    }
  }).then(res => res.data)
}


export const apiRetrieveBookingsWithParams = ({ params, token }) => {
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/retrieve-bookings/${params.company_abbreviation}/${params.root_res_code}`,
    headers: token,
    // params
  }).then(res => res.data)
}

export const apiRetrieveBookingsByNameList = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/retrieve-by-namelist',
    headers: token,
    data
  }).then(res => res.data)
}

export const apiRetrieveActiveOptionalBookings = ({ params, token }) => {
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/active-optional-bookings/${params.fromDate}/${params.toDate}`,
    headers: token,
  }).then(res => res.data)
}

export const apiRetrieveGroupBookings = ({ params, token }) => {
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/retrieve-group-booking/${params.company_abbreviation}/${params.root_res_code}`,
    headers: token,
    // params
  }).then(res => res.data)
}

export const apiUpdateGroupBookings = ({ data, token, company_abbreviation, cmp_reservation_code }) => {
  return axios({
    method: 'PUT',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/update-group-booking/${company_abbreviation}/${cmp_reservation_code}`,
    headers: token,
    data
  }).then(res => res.data)
}


/**
 * This method fetches a previously booked (optional o).
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B/doRetrieveBooking_1
 *
 * @param { Object } data
 * @property { Object } data.params - {
 *  crsReservationIds: string,
 *  crsReservationId: string,
 *  ticketNumber: string,
 *  fromCode: string,
 *  toCode: string,
 *  departureDate: string,
 *  arrivalDate: string,
 *  surname: string,
 *  plateNumber
 * }
 * @property { String } data.token
 * @returns {Promise<Array>}
 */
export const apiRetrieveOptionalBookings = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/retrieve-optional',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  }).then(res => res.data)

export const apiRetrieveProjects = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/projects',
    headers: token,
    params: {
      active: true
    }
  }).then(res => res.data)

export const apiValidateVoucher = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/validate-voucher/${params.id}${params.code ? '?voucher-code=' + params.code : ''}`,
    headers: token,
    // params
  }).then(res => res.data)

  export const apiRetrieveProjectInfo = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/project-info/${params.id}`,
    headers: token,
    // params
  }).then(res => res.data)
  
export const apiTransferTickets = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/transfer-tickets',
    headers: token,
    data
  }).then(res => res.data)

/* This method issue a ticket or a whole booking */

export const issueBooking = ({ data, token }) => {
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/issue-booking',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      bookingPaymentMethods: data.paymentMethods
        ?.filter(m => m.ammount > 0)
        .map(method => ({ paymentMethod: method.id, total: parseInt(method.ammount * 100) }))
    }
  }).then(res => res.data)
}


export const insurance = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/insurance-offer',
    headers: {...token,  "language-code": $nuxt.$i18n.locale},
    data: {
      ...data,
    }
  }).then(res => res.data)

export const sendInsurance = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/send-insurance-offer-to-client',
    headers: token,
    data: {
      ...data,
    }
  }).then(res => res.data)

/**
 * This method converts a booking to open
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - Booking {
 *  crsReservationId: number,
 *  tickets: [
 *    {
 *      "priceAccommodationType": "PASSENGER",
 *      "letter": "string",
 *      "number": "string",
 *    }
 *  ],
 * @property { String } params.token
 * @returns {Promise<Array>}
 */
export const apiConvertBookingToOpen = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/convert-booking-to-open',
    headers: token,
    data
  }).then(res => res.data)


export const apiRetrieveBookingsWithCriteria = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/retrieve-booking-by-criteria',
    headers: token,
    data
  }).then(res => res.data)
}

/**
 * This method cancels a booking
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - Booking {
 *  crsReservationId: number,
 *  tickets: [
 *    {
 *      "priceAccommodationType": "PASSENGER",
 *      "letter": "string",
 *      "number": "string",
 *    }
 *  ],
 * @property { String } params.token
 * @returns { Promise }
 */
export const apiBookingCancel = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/multi-cancellation',
    headers: token,
    data
  }).then(res => res.data)

/**
 * This method creates an optional booking of a specific quote. The criteria entity is called “Booking request”. The structure of the entity can be found on the table, below.
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B/doOptionalBooking
 *
 * @param { Object } params
 * @property { Object } params.data - Booking
 * @property { String } params.token
 * @returns { Promise }
 */
export const apiOptionalBooking = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/multi-optional-booking',
    headers: token,
    data
  }).then(res => res.data)

export const apiAddItemsToBooking = ({ data, token }) =>
  axios({
    method: 'PUT',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/add-items-to-booking',
    headers: token,
    data
  }).then(res => res.data)

/**
 *  The method splits a booking into two.
 *  documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - Booking
 * @property { String } params.token
 * @returns { Promise }
 */
export const apiSplitBooking = ({ data, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/split-booking/${data.crsReservationId}`,
    headers: token,
    params: {
      'fetch-bookings': data.fetchBookings,
    },
  }).then(res => res.data)

/**
 *  The method merge a booking with another.
 *  documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/ui/index#/
 *
 * @param { Object } params
 * @property { Object } params.data - Booking
 * @property { String } params.token
 * @returns { Promise }
 */
export const apiMergeBookings = ({ data, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/merge-bookings/${data.fromReservationId}/${data.toReservationId}`,
    // headers: {
    //   Authorization: `Bearer ${token}`
    // },
    headers: token,
    // data
  }).then(res => res.data)

/**
 * This method gets the pdf for a specific booking
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B
 *
 */
export const printPdf = ({ crsReservationId, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/booking-pdf',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      crsReservationId
    }
  }).then(res => res.data)

/**
 * This method sends an email for a specific booking
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B
 *
 */
export const sendEmail = ({ crsReservationId, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/send-email',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      crsReservationId
    }
  }).then(res => res.data)

/**
 * This method sends an sms for a specific booking
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B
 *
 */
export const sendSms = ({ crsReservationId, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/send-sms',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      crsReservationId
    }
  }).then(res => res.data)

/**
 * This method get the lastest agency booking
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B
 *
 */
export const latestBookingAvailable = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/retrieve-the-last-booking',
    headers: token,
    params
  }).then(res => res.data)

  
/**
 * This method get all the available passenger types
 *
 */
export const availablePassengerTypes = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/passenger-types',
    headers: token,
    params
  }).then(res => res.data)

/**
 * This method get the available attributes for editing.
 * documentation: https://devsrv.forth-crs.gr/cws/swagger-ui/#/B2B
 *
 */
export const modifiableAttributes = ({ data, token }) =>
  axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/modifiable-attributes',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  }).then(res => res.data)

/**
 * This method returns the available actions per passenger.
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/index.html
 *
 */
export const allowedActions = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/allowed-ticket-actions',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  }).then(res => res.data)

/**
 * This method returns the financial data for the specific agent.
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/index.html
 *
 */
export const financialData = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/financial-data',
    headers: token,
    params
  }).then(res => res.data)

  
/**
 * This method retrieves the cancellation fees of a ticket.
 *
 */
  export const cancellationFees = ({ params, token }) => {
    return axios({
      method: 'POST',
      baseURL: BASE_URL_API_GUI,
      url: '/cancellation-fees',
      headers: {
        ...token,
        'Content-Type': 'application/json'
      },
      data: params
    })
    .then(res => res.data)
  }


/**
 * This method returns info about the cashier
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/index.html
 *
 */
export const cashierData = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/cashier-data/${params.start_date.split('/').join('-')}/${params.finish_date.split('/').join('-')}`,
    headers: token
    // params
}).then(res => res.data) 

export const statisticsData = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/statistics?issueFrom=${params.start_date.split('/').join('-')}&issueTo=${params.finish_date.split('/').join('-')}&tripFrom=${params.trip_start_data.split('/').join('-')}&tripTo=${params.trip_finish_data.split('/').join('-')}&passType=${params.passType}&vesselName=${params.vessel}&agencyUserName=${params.agencyUserName}&companyAbbreviation=${params.company}&subAgencyCode=${params.subAgencyCode}&customerName=${params.customerName}&locationFrom=${params.locationFrom}&locationTo=${params.locationTo}&page=${params.numOfpage}&size=100`,
    headers: token
}).then(res => res.data) 


export const residentCitiesList = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: `/resident-static-data?company-abbreviation=${params}`,
    headers: token,
  }).then(res => res.data)




/**
 * This method returns info about the agency
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/index.html
 *
 */
export const agencyInfo = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/agency-info',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  }).then(res => res.data)

/**
 * This method check if open ticket is valid.
 * documentation: http://apps.develop.zappdev.com/Seaonline_5112_dev/swagger/index.html
 *
 */
export const validateOpenTicket = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/open-ticket-info',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  }).then(res => res.data)

export const availableAttributes = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/attributes',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  }).then(res => res.data)

export const issueFromOptional = ({ data, token }) =>{
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/issue-multi-booking/${data.crsReservationId}`,
    headers: token,
    params:{
      pta: data.pta ? data.pta : undefined
    }
  }).then(res => res.data)
}
export const issueFromOptionalGroup = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/print-group-booking',
    headers: token,
    data: {
      crsReservationId:data.crsReservationId,
      companyAbbreviation: data.companyAbbreviation,
      isPta:data.pta,
    },
  }).then(res => res.data);
};


export const issueFromPta = ({ data, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/print-booking-to-thermal/${data.crsReservationId}`,
    headers: token,
}).then(res => res.data)

export const apiConvertBookingToOptional = ({ data, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/convert-to-optional-booking/${data.crsReservationId}`,
    headers: token
  }).then(res => res.data)

export const getClearanceDownloadId = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/company-clearings/${params.company_abbreviation}`,
    headers: token,
    params: {
      year: params.year,
      number: params.number,
      'file-type': params.fileType
    }
  }).then(res => res.data)

export const downloadClearance = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/download-clearance',
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  }).then(res => res.data)

export const getCompanies = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/companies',
    headers: {...token,  "language-code": $nuxt.$i18n.locale},
    params
  }).then(res => res.data)

export const getPrintingModels = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/printing-models ',
    headers: { Authorization: `Bearer ${token}` },
    params
  }).then(res => res.data)

export const getAccommdations = ({ params, token }) =>
  axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/accommodations',
    headers: token,
    params
  }).then(res => res.data)

export const getSmallPackages = ({ data, token }) => {
  const [date, time] = data.departureDate.split(' ')
  return axios({
    method: 'GET',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/small-packages-list-names/${data.company}?originIdOrCode=${data.originIdOrCode}&destinationIdOrCode=${data.destinationIdOrCode}&vesselIdOrCode=${parseInt(data.vesselIdOrCode)}&departureDate=${date}&departureTime=${time}`,
    headers: token
  }).then(res => res.data)
}

export const getSmallPackagesPricing = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/small-packages-pricing',
    headers: token,
    data
  }).then(res => res.data)
}

export const getSmallPackagesBooking = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + '/small-packages-booking',
    headers: token,
    data
  }).then(res => res.data)
}

export const getSmallPackagesCancel = ({ data, token }) => {
  return axios({
    method: 'PUT',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/small-packages-cancel/${data.company}?crsReservationCode=${data.crsReservationCode}&cmpReservationCode=${data.cmpReservationCode}`,
    headers: token
  }).then(res => res.data)
}

export const alternateAgencies = ({ data, token }) => {
  return axios({
    method: 'POST',
    baseURL: BASE_URL_API_GUI,
    url: process.env.API_BASE_PATH_GUI + `/alternate-agencies`,
    headers: token,
    data
  }).then(res => res.data)
}
