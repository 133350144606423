// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/webpack/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@nuxt/webpack/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/logo_AgencyX.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/naxos_cover.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".whats-new-container[data-v-6e5f6012]{position:fixed;top:0;left:0;z-index:2;width:100%;height:100%;background:rgba(0,0,0,.5);display:flex;align-items:center;justify-content:center}.window[data-v-6e5f6012]{width:35vw;height:80vh;background:#fff;border:1px solid #ccc;border-radius:5px;position:relative;border:2px solid var(--main-color)}.liknoss-logo[data-v-6e5f6012]{height:100px;width:100px;border:4px solid var(--main-color);border-radius:50px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:#fff;background-size:contain;position:absolute;top:-50px;left:50%;transform:translateX(-50%)}.liknoss-logo[data-v-6e5f6012],.top-container[data-v-6e5f6012]{background-repeat:no-repeat;background-position:50%}.top-container[data-v-6e5f6012]{height:30%;width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:cover}.bottom-container[data-v-6e5f6012]{height:70%;width:100%;padding:20px;overflow:auto}h2[data-v-6e5f6012]{font-size:20px}h2[data-v-6e5f6012],p[data-v-6e5f6012]{margin:10px 0}p[data-v-6e5f6012]{font-size:14px}button[data-v-6e5f6012]{position:absolute;top:-55px;right:-50px;transform:translateX(-50%);background-color:transparent;border:none;cursor:pointer;color:#fff}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
